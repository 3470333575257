import { Chip, Icon, Stack, Tooltip, styled } from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { ListItemText, List, ListItemIcon, ListItem } from '@mui/material';
import React from "react";
import { SongHead } from "../../model/SongHead";
import LocalDatabase from "../../repository/LocalDatabase";
import { getSongBySlug } from "../../services/SongServiceBis";
import { useNavigate } from "react-router-dom";

type Props = {
    songs: SongHead[], 
    bucket: string, 
    abc: boolean,
    useGregorianChords?: boolean
}

const StyledList = styled(List)`
    overflow: auto;

    & .card {
        margin: .5rem 1rem;
        border-radius: .5rem;
    }

    @media (min-width: 1200px) {

        & .card {
            margin: 1.5rem 1rem;
        }
    }
`

const StyledListItemTag = styled(ListItemIcon)`
    width: 30%;
    display: contents;
`

const StyledTagSpace = styled(Stack)`
    display: inline-block;
`

const StyledChip = styled(Chip)`
    margin-bottom: 4px;
`

export const SongList = (props: Props) => {

    const localDatabase = LocalDatabase();
    const navigate = useNavigate();

    const downloadSong = (e:any) => {
        e.preventDefault();
        let component = e.target;
        let slug = component.getAttribute("data-slug");
        if (null == slug) {
            component = e.target.parentNode;
            slug = component.parentNode.getAttribute("data-slug");
        }
        getSongBySlug(props.bucket, slug)
                    .then(s=>{
                        localDatabase.addSongToLocalStorage(s)
                        component.style.display = "none";
                    })
    }

    return (
        <StyledList>
            {props.songs.map((d, idx)=>{
                const viewSongUrl = props.useGregorianChords ? "/" + d.slug + '/view/it' : "/" + d.slug + '/view';
                return (<div key={idx} className="card">
                            <ListItem key={idx}>
                                <Icon sx={{ display: 'flex', width: 'auto', height: 'auto', 
                                                        backgroundColor: 'var(--color-background)',
                                                        borderRadius: '15px' }} >
                                    <img src={ `/logo192-transparent.webp` } aria-label="Accordi Sbagliati Logo" width={ 24 } />
                                </Icon>
                                { (false) && (
                                <ListItemIcon>
                                    { d.isLocal ? 
                                            <Tooltip title={"already available offline"}>
                                                <OfflinePinIcon style={{ marginRight: "15pt"}} color="success" /> 
                                            </Tooltip> 
                                            : <Tooltip title={"make available offline"}>
                                            <DownloadForOfflineIcon 
                                                color="action"
                                                style={{ marginRight: "15pt", cursor: "pointer"}} 
                                                onClick={ downloadSong } 
                                                data-slug={d.slug} />
                                            </Tooltip>
                                    }
                                </ListItemIcon> ) }
                                <ListItemText onClick={() => navigate(viewSongUrl)} primary={d.title} secondary={d.artist} style={{ marginLeft: "15pt" }} />    
                                <StyledListItemTag>
                                    <StyledTagSpace direction="row" spacing={1}>
                                        {d.tags?.map((e,i)=><StyledChip className="me-1" key={i} label={e} onClick={() => {
                                                navigate('/' + d.bucket + '/tag/' + e)
                                                window.location.reload();
                                            }} />)}
                                    </StyledTagSpace>
                                </StyledListItemTag>    
                            </ListItem>
                        </div>)
            })}
            </StyledList>)
} 
