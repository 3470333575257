import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Menu from "../Menu/Menu";

type Props = {
    abc?: boolean
}

class PageNotFound extends React.Component<Props> {
    render(){
        return <div>
                <Menu abc={this.props.abc} />
                <div className="container mt-3">
                    <h3>Page Not Found</h3>
                    <p style={{textAlign:"center"}}>
                    <Link to="/">Back to Home</Link>
                    </p>
                </div>
          </div>;
    }
}

export default function NotFoundPage(props: Props) {
    const params = useParams();
    return <PageNotFound {...props} {...params} />
}
