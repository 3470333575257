import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import React, { KeyboardEvent, useContext, useState } from "react";
import { Button, styled } from "@mui/material";
import { SearchContext } from "../../SearchContext";

interface Props {
  onSearch: (value: string) => void;
}

const StyledTextField = styled(TextField)`
  background-color: #ffffff;
`

export const SearchInput: React.FC<Props> = (props: Props) => {  
  const searchContext = useContext(SearchContext);
  const [searchValue, setSearchValue] = useState<string>(searchContext.text);

  const doSearch = (event: any) => {
    //event.preventDefault();
    props.onSearch(event.target.value);
  };

  const onChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  return (
    <StyledTextField
      fullWidth
      sx={{
        "& .MuiInputLabel-root": {color: 'green'},//styles the label
        "& .MuiOutlinedInput-root": {
          "& > fieldset": { borderColor: "#1976d2" },
        },
        "& .MuiOutlinedInput-root:hover": {
        "& > fieldset": {
            borderColor: "white"
        }
        }
      }}
      value={searchValue}
      //defaultValue={searchContext.text}
      variant="outlined"
      onKeyUp={(e: KeyboardEvent)=>{ 
        console.log("S: ", searchContext.text)
        e.key === 'Enter' && doSearch(e)
      }}
      onChange={onChange}
      InputProps={{
        style: { 
            color: "var(--color-background)" 
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: "var(--color-background)" }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button variant="contained" onClick={doSearch}>Search</Button>
              <IconButton onClick={()=>{ 
                  setSearchValue('');
                  doSearch('')
               }}>
                <SearchOffIcon style={{ color: "var(--color-background)" }} />
              </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};