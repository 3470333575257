import React from "react";
import { saveAs } from "file-saver";
import { FileDownload } from "@mui/icons-material";
import { Box, Fab, Tooltip, Typography } from "@mui/material";
import { exportSong } from "../../../../services/SongServiceBis";

type Props = {
    bucket: string
    fab?: boolean
}

export const ExportBucket = (props: Props) => {

    const onExport = (e: any) => {
        e.preventDefault()
        exportSong(props.bucket)
                .then(res=>res.blob())
                .then(blob=>saveAs(blob, props.bucket + ".zip"))
                .catch(err=>console.log(err))
    }

    return props.fab ?
            <Fab onClick={e=>onExport(e)}>
                <Tooltip title={"export songbook"}>
                    <FileDownload />
                </Tooltip>
            </Fab> :
            <Box sx={{ p: 2 }}
                style={{ textAlign: 'center', cursor: 'pointer' }} 
                onClick={e=>onExport(e)}>
                    <Tooltip title={"export songbook"}>
                        <FileDownload />
                    </Tooltip>
                    <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        Export
                    </Typography>
            </Box>
    
}