import React, { ReactNode, useState } from 'react';
import './Menu.css';

import 'rc-slider/assets/index.css';
import { Fab, List, ListItem, styled } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import { mediaQueries } from '../../config/mediaQueries';


type MenuProps = { children: ReactNode[] }

const Menu = styled(List)`
    position: fixed;
    margin-top: .5rem;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 99;

    li {
        justify-content: flex-end;
    }

    @media ${mediaQueries.tablet} {
        right: 2.0rem;
        top: auto;
    }
`;

export const Part = styled('div')`
    border-radius: 0.5rem;
    display: none;
    margin-right: 1rem; 

    transition: display .5s linear;
`;

const StyledFab = styled(Fab)`
    background-color: var(--color-background); 
    color: var(--color-foreground);

    &:hover {
        background-color: var(--color-background); 
        color: var(--color-foreground);
    }
`

const IconMenu = (props: MenuProps) => {
    
    const [ isExpanded, setIsExpanded ] = useState<boolean>(false);
    
    return <Menu>
            <ListItem>
            <StyledFab sx={{ backgroundColor: 'var(--color-background)', color: 'var(--color-foreground)'}}
                aria-label="edit" 
                onClick={(e) => {
                    if (isExpanded) {
                        e.currentTarget.classList.remove("rotate")
                        e.currentTarget.classList.add("rotateBack")
                        // searchExpanded.current = false;
                        // transposeExpanded.current = false;
                        setTimeout(() => setIsExpanded(false), 400)
                    } else {
                        e.currentTarget.classList.remove("rotateBack")
                        e.currentTarget.classList.add("rotate")
                        setTimeout(() => setIsExpanded(true), 400)
                    }
                }}>
                <MenuIcon />
            </StyledFab>
            </ListItem>

            { isExpanded &&
            <List>
                { props.children }
            </List>
            }   
        </Menu>
}

export default IconMenu;