import React, { MouseEvent, MouseEventHandler } from "react";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip, Typography } from "@mui/material";
import { deleteSong, splitSlug } from "../../services/SongServiceBis";
import { Slug } from "../../services/ISongService";
import { StyledBox } from "../Menu/Menu";

type Props = {
  songSlug: string,
}

export const DeleteSongIcon = (props: Props) => {

    const navigate = useNavigate();

    const onDelete: MouseEventHandler = (event: MouseEvent<SVGSVGElement>) => {
        event.stopPropagation();
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want delete ' + props.songSlug +'?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteFunction()
              },
              {
                label: 'No',
                onClick: () => {}
              }
            ]
          });
    }

    const deleteFunction = (): void => {
        const slug: Slug = splitSlug(props.songSlug)
        
        deleteSong(slug.bucket, slug.artist, slug.title)
          .then(message=>{
            if (message.outcome) {
              navigate(message.message)
            } else {
              let err = message.message
              if (err.response && err.response.status === 401) {
                  navigate("/login")
              } else {
                  showError('Error', message.message);
              }
            }
          })
    }

    const showError = (title: string, message: any) => {
      confirmAlert({
        title: title,
        message: String(message),
        buttons: [
          {
            label: 'Ok',
            onClick: () => {}
          }
        ]
      });
    }

    
    return (<StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={onDelete}>
              <Tooltip title={"delete song"}>
                <DeleteIcon data-testid="delete-icon" />
              </Tooltip>
              <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Delete</Typography>
            </StyledBox>)
    
}
