import { ListItem, Fab, Tooltip } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import React from "react";
import { isUserAuthenticated } from "../../../services/AuthServiceBis";

export const LogoutButton = () => {
    return isUserAuthenticated() &&
        <ListItem>
            <Fab onClick={()=>window.location.href='/logout'}>
                <Tooltip title="Logout">
                    <LogoutIcon />
                </Tooltip>
            </Fab>
        </ListItem> 
}

