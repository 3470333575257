import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.svg`
    position: relative;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.35rem;
`;

const MusicIcon = styled(MusicNoteIcon)`
    position: absolute;
    transform: scale(0.5);
    top: 1pt;
    z-index: 100;
`;
const PasteIcon = styled(ContentPasteIcon)`
    position: absolute;
    transform: scale(1.5);
    z-index: 50;
`;

export const PasteChordsIcon = ()  => {
    return (<IconContainer>
                <MusicIcon />
                <PasteIcon />
            </IconContainer>)
}