import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ViewSong from './component/ViewSong/ViewSong';
import ViewBucket from './component/ViewBucket/ViewBucket';
import { Login } from './component/Login/Login';
import EditSong from './component/EditSong/EditSong';
import NotFoundPage from './component/NotFoundPage/NotFoundPage';
import { DBConfig } from './config/DbCongif';
import { initDB } from 'react-indexed-db';
import Logout from './component/Login/Logout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WebFont from 'webfontloader';
import { SongContext } from './component/atoms/SongContext';
import { SongHead } from './model/SongHead';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#fAfAfA',
    },
  },
});

initDB(DBConfig);

const App = () => {

    // https://fonts.google.com/
    useEffect(() => {
      WebFont.load({
        google: {
          families: [ 'Roboto', 'Inter', 'Raleway']
        }
      });
    }, []);

    const [songListMap, setSongListMap] = useState(new Map<string, SongHead[]>());
    
    const updateSongListMap = useCallback((key: string, value: SongHead[]) => {
      songListMap.set(key, value);
      setSongListMap(songListMap);
    }, [ songListMap ])

    const songContextValue = useMemo(() => ({ 
        songListMap, 
        updateSongListMap 
      }), [songListMap, updateSongListMap]);

    return (
      <ThemeProvider theme={theme}>
        <SongContext.Provider value={songContextValue}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ViewBucket bucket="gole" />} />
              <Route path="/it" element={<ViewBucket bucket="gole" useGregorianChords={true} />} />
              <Route path="/:bucket" element={<ViewBucket />} />
              <Route path="/:bucket/it" element={<ViewBucket useGregorianChords={true} />} />
              <Route path="/:bucket/tag/:tag" element={<ViewBucket />} />
              <Route path="/tag/:tag" element={<ViewBucket bucket="gole" />} />
              <Route path="/:bucket/artist/:artist" element={<ViewBucket />} />
              <Route path="/artist/:artist" element={<ViewBucket bucket="gole" />} />
              <Route path="/:bucket/:artist/:title/view" element={<ViewSong />} />
              <Route path="/:bucket/:artist/:title/view/it" element={<ViewSong useGregorianChords={true} />} />
              <Route path="/it/:bucket/:artist/:title/view" element={<ViewSong useGregorianChords={true} />} />
              <Route path="/:bucket/:artist/:title/abc/edit" element={<EditSong abc={true} />} />
              <Route path="/:bucket/:artist/:title/edit" element={<EditSong />} />
              <Route path="/:bucket/new" element={<EditSong />} />
              <Route path="/new" element={<EditSong bucket="gole" />} />
              <Route path="/login" element={<Login redirect="/" />} />
              <Route path="/logout" element={<Logout redirect="/" />} />
              <Route path="/404" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </SongContext.Provider>
      </ThemeProvider>
    );
}

export default App;
