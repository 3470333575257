export const DBConfig = {
    name: 'ChordProdDB',
    version: 1,
    objectStoresMeta: [
        {
            store: 'songs',
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'title', keypath: 'title', options: { unique: false } }, 
                { name: 'artist', keypath: 'title', options: { unique: false } },
                { name: 'bucket', keypath: 'bucket', options: { unique: false } },
                { name: 'slug', keypath: 'slug', options: { unique: true } },
                { name: 'raw', keypath: 'raw', options: { unique: false } },
                { name: 'abc', keypath: 'abc', options: { unique: false } },
                { name: 'lastUpdate', keypath: 'lastUpdate', options: { unique: false } },
                { name: 'tags', keypath: 'tags', options: { unique: false } },
                { name: 'chords', keypath: 'chords', options: { unique: false } }
            ]
        }
    ]
};