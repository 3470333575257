import { Song as SongChordsheetJS } from "chordsheetjs"
import React from "react";
import { toGregorianChord } from "../utils/chords";

type LineItem = {
    chords: string,
    lyrics: string
}

type MetaItem =  {
    line: number,
    column: number,
    offset: number,
    _originalName: string,
    _name: string,
    _value: string,
    _isMetaTag: boolean
}
const isMetaItem = (x: any): x is MetaItem => (typeof x === 'object' && x['line'] !== undefined);
const isLineItem = (x: any): x is LineItem => (typeof x === 'object' && x['chords'] !== undefined && x['lyrics'] !== undefined);

const replaceAllWhiteSpace = (x: string): string => {
    let re = new RegExp(/\s+/g)
    return x.replace(re, '\u00A0')
}

type Formatter = {
    format: (parsed: SongChordsheetJS, useGregorianChords: boolean) => JSX.Element
} 

const DivFormatter = (): Formatter => {

    const parseChord = (chord: string, useGregorianChords: boolean) => {
        return chord.length === 0 ? '\u00A0' : useGregorianChords ? toGregorianChord(chord) : chord;
    }

    const addWhiteSpace = (line: string, howMany: number) => {
        for (let i = 0; i < howMany; i++) {
            line += '\u00A0';
        }
        return line;
    }

    const getRow = (chords: string, lyrics: string, index: number, classNames?: string) => {
        if (chords.length === 0 && lyrics.length === 0) return null;
        return <div className={'row ' + classNames} key={index}>
                <div className='chord'>{chords}</div>
                <div className='lyrics'>{lyrics}</div>
            </div>;
    }

    return {
        format(parsed: SongChordsheetJS, useGregorianChords: boolean): JSX.Element {
            let html: JSX.Element[] = [];
            let classNames = '';
            parsed.lines.forEach((line, idx) => {
                let chords = '';
                let lyrics = '';
                line.items.forEach(item => {
                    if (isLineItem(item)) {
                        let diff = item.lyrics.length - item.chords.length;
                        if (diff < 0) chords = addWhiteSpace(chords, 1);
                        chords += parseChord(item.chords, useGregorianChords);
                        if (diff > 0)chords = addWhiteSpace(chords, diff);
                        lyrics += replaceAllWhiteSpace(item.lyrics);
                    } else if (isMetaItem(item)) {
                        if (item._name === 'start_of_chorus') {
                            html.push(getRow('\u00A0', 'Rit', idx, 'italic'));
                            classNames = 'chorus';
                        } 
                        else if (item._name === 'end_of_chorus') {
                            classNames = '';
                        }
                    }
                })
                const row = getRow(chords, lyrics, idx, classNames);
                if (row !== null) {
                    html.push(row);
                }
            })
            return <div className="box">{html}</div>;
        }
    }
}

export default DivFormatter;