const BRITISH_TONES = [ 'A', 'A#', 'Bb', 'B', 'C', 'C#', 'Db', 'D', 'D#', 'Eb', 'E', 'F', 'F#', 'Gb', 'G', 'G#', 'Ab' ]
const ITALIAN_TONES = [ 'LA', 'LA#', 'SIb', 'SI', 'DO', 'DO#', 'REb', 'RE', 'RE#', 'MIb', 'MI', 'FA', 'FA#', 'SOLb', 'SOL', 'SOL#', 'LAb' ]

const getBaseChord = (chord: string) => {
    if (chord.length > 1) {
        const base = chord.substring(0, 2);
        if (BRITISH_TONES.includes(base)) return base;
    }
    return chord.substring(0, 1);
}

export const toGregorianChord = (chord: string) => {
    const base = getBaseChord(chord);
    const index = BRITISH_TONES.indexOf(base);
    return index >= 0 ? ITALIAN_TONES[index] + chord.substring(base.length) : chord;
}