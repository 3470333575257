import React from 'react';
import './Menu.css';
import { SearchInput } from '../atoms/Buttons/Search/SearchInput';
import { ExportBucket } from '../atoms/Buttons/ExportBucket/ExportBucketIcon';
import { Transpose } from "../atoms/Buttons/Transpose/Transpose";
import 'rc-slider/assets/index.css';
import { DeleteSongIcon } from '../atoms/DeleteSongIcon';
import { Box, CircularProgress, Icon, Toolbar, Tooltip, Typography, styled } from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DownloadBucketIcon from '../atoms/Buttons/DownloadBucket/DownloadBucketIcon';
import { CopyChordsIcon } from '../atoms/CopyChordsIcon';
import { PasteChordsIcon } from '../atoms/PasteChordsIcon';
import { ClearChordsIcon } from '../atoms/ClearChordsIcon';
import { isUserAuthenticated } from '../../services/AuthServiceBis';

type MenuProps = {
    handleSearch?: (value: string) => void;
    transpose?: React.ChangeEventHandler<HTMLSelectElement>,
    scrollSong?: any,
    onMagicWand?: (e: any) => void,
    abc?: boolean,
    bucket?: string,
    filter?: string,
    onSave?: (e:any) => void,
    onDelete?: string,
    addSongUrl?: string,
    editSongUrl?: string,
    exportBucket?: string,
    username?: string,
    onCopyChords?: (e: any) => void,
    onPasteChords?: (e: any) => void,
    onClearChords?: any,
    onDownloadBucket?: string,
    waitingIcon?: boolean,
    useGregorianChords?: boolean,
}

const StyledToolbar = styled(Toolbar)`
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0 10rem;
`;

export const StyledBox = styled(Box)`
    color: white;
`;

const Menu = (props: MenuProps) => {
        
    let homeLink = undefined === props.bucket ? "/" : "/" + props.bucket
    if (props.useGregorianChords) {
        homeLink += "/it";
    }

    return <StyledToolbar disableGutters sx={{ justifyContent: "space-between" }}>
                    {/* <SvgIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} 
                            onClick={()=>window.location.href=homeLink}
                            style={{cursor:'pointer'}}>
                        <image href={ `/logo192.png` } aria-label="Accordi Sbagliati Logo" width={24}/>
                    </SvgIcon> */}

                    <Icon onClick={()=>window.location.href=homeLink} 
                        sx={{ display: 'flex', width: 'auto', height: 'auto', 
                                                        backgroundColor: 'var(--color-accent)',
                                                        borderRadius: '15px' }} >
                            <img src={ `/logo192-transparent.webp` } aria-label="Accordi Sbagliati Logo" width={ 24 } />
                    </Icon>
                    { props.transpose && <Transpose onChange={props.transpose} useGregorianChords={props.useGregorianChords} />}
                    { props.handleSearch &&
                        <StyledBox width="100%">
                            <SearchInput 
                                onSearch={props.handleSearch}
                                />
                        </StyledBox> }
                
                    { isUserAuthenticated() && props.exportBucket && <ExportBucket bucket={props.exportBucket} /> }    
                    { isUserAuthenticated() && props.onDownloadBucket && <DownloadBucketIcon bucket={props.onDownloadBucket} />}
                        
                    { isUserAuthenticated() && props.editSongUrl &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} 
                                onClick={()=>window.location.href=props.editSongUrl}>
                            <Tooltip title={"edit"}>
                                <EditIcon />
                            </Tooltip>
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Edit</Typography>
                        </StyledBox>}
                    { isUserAuthenticated() && props.addSongUrl &&
                        <StyledBox style={{ textAlign: 'center', cursor:"pointer" }} 
                            onClick={()=>window.location.href=props.addSongUrl}>
                            <Tooltip title={"add song"}>
                                <LibraryAddIcon />
                            </Tooltip>
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Add Song</Typography>
                        </StyledBox> }
                    { props.onMagicWand &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={props.onMagicWand}>
                            <AutoFixHighIcon />
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Magic Wand</Typography>
                        </StyledBox> }
                    { props.onCopyChords &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', 
                                                    cursor:"pointer" }} onClick={props.onCopyChords}>
                            <CopyChordsIcon />
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Copy Chords</Typography>
                        </StyledBox> }
                    { props.onPasteChords &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={props.onPasteChords}>
                            <PasteChordsIcon />
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Paste Chords</Typography>
                        </StyledBox> }
                    { props.onClearChords &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={props.onClearChords}>
                            <ClearChordsIcon />
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Clear Chords</Typography>
                        </StyledBox>}

                    { !isUserAuthenticated() &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={()=>window.location.href='/login'}>
                            <LoginIcon />
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Login</Typography>
                        </StyledBox>}
                    { props.waitingIcon &&
                        <StyledBox sx={{ width: '100%' }} style={{ color: 'white' }}>
                            <CircularProgress size="small" style={{ color: "white" }} />
                        </StyledBox>  }
                    { isUserAuthenticated() && 
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={()=>window.location.href='/logout'}>
                            <LogoutIcon />
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Logout</Typography>
                        </StyledBox> }
                    { isUserAuthenticated() && props.onSave &&
                        <StyledBox sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }} onClick={props.onSave}>
                            <Tooltip title={"save song"}>
                                <SaveIcon />
                            </Tooltip>
                            <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Save</Typography>
                        </StyledBox> }
                    { isUserAuthenticated() && props.onDelete && <DeleteSongIcon songSlug={props.onDelete} />}
                </StyledToolbar>
       
}

export default Menu;