import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.svg`
    position: relative;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.35rem;
`;

const MusicIcon = styled(MusicNoteIcon)`
    position: absolute;
    transform: scale(0.5);
    padding: 1pt;
    z-index: 100;
`;
const CopyIcon = styled(ContentCopyIcon)`
    position: absolute;
    z-index: 50;
`;

export const CopyChordsIcon = ()  => {
    return (<IconContainer>
                <MusicIcon />
                <CopyIcon />
            </IconContainer>)
}