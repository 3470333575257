import { ListItem } from "@mui/material";
import React from "react";
import { isUserAuthenticated } from "../../../../services/AuthServiceBis";
import { ExportBucket } from "./ExportBucketIcon";

export const ExportBucketButton = (props: {
    bucket: string;
}) => {
    return isUserAuthenticated() &&
            <ListItem>
                <ExportBucket fab={true} bucket={props.bucket} />
            </ListItem> 
}

