import { Fab, ListItem, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import { Part } from "../../../Menu/IconMenu";
import { SearchInput } from "./SearchInput";
import SearchIcon from '@mui/icons-material/Search';

export const SearchButton = (props: {
    handleSearch?: (value: string) => void;
    
}) => {
    const searchBoxRef = useRef(null);
    const searchExpanded = useRef<boolean>(false)
    
    return <ListItem>
    <Part ref={searchBoxRef}>
        <SearchInput onSearch={props.handleSearch} />
    </Part>
        <Fab onClick={()=>{
                if (!searchExpanded.current) {
                    searchBoxRef.current.parentElement.parentElement.parentElement.style.width = '90%';
                    searchBoxRef.current.parentElement.style.backgroundColor = 'var(--color-background)';
                    searchBoxRef.current.parentElement.style.borderRadius = '.5rem';
                    searchBoxRef.current.style.display = 'block';
                    searchBoxRef.current.style.width = '100%';
                    searchExpanded.current = true;
                } else {
                    searchBoxRef.current.parentElement.parentElement.parentElement.style.width = 'auto';
                    searchBoxRef.current.parentElement.style.backgroundColor = 'transparent';
                    searchBoxRef.current.style.display = 'none';
                    searchBoxRef.current.style.width = 'auto';
                    searchExpanded.current = false;
                }
            }}>
            <Tooltip title={"search"}>
                <SearchIcon />
            </Tooltip>
        </Fab>
    </ListItem>
}