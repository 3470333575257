import { ListItem, Fab, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import { isUserAuthenticated } from "../../../services/AuthServiceBis";

export const EditSongButton = (props: { editSongUrl: string }) => {
    return isUserAuthenticated() && 
            <ListItem>
                <Fab onClick={()=>window.location.href=props.editSongUrl}>
                    <Tooltip title={"edit"}>
                        <EditIcon />
                    </Tooltip>
                </Fab>
            </ListItem> 
}

