import React, { FormEvent, FormEventHandler, useRef } from "react";
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import { Button, Container, FormGroup, Input, InputLabel } from "@mui/material";
import { login } from "../../services/AuthServiceBis";

type Props = {
    redirect?: string
}

export const Login = (props: Props) => {

    const navigate = useNavigate();
    const usernameInput = useRef(null);
    const passwordInput = useRef(null);

    const handleLogin: FormEventHandler<HTMLFormElement> = (e: FormEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (usernameInput.current != null && 
            passwordInput.current != null) {
            const username = usernameInput.current?.firstChild.value
            const password = passwordInput.current?.firstChild.value
            login(username, password)
                .then(message=>{
                    if (message.outcome) {
                        navigate(props.redirect)
                    } else {
                        confirmAlert({
                            title: 'Authentication Error',
                            message: 'Bad Credentials',
                            buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {}
                            }
                            ]
                        });
                    }
            })
        }
    }

    return (
        <Container sx={{ mt: 3 }}>
            <form onSubmit={handleLogin}>
                <FormGroup sx={{ mb: 3 }}>
                    <InputLabel>Username</InputLabel>
                    <Input type="text" placeholder="Enter username" ref={ usernameInput } />
                </FormGroup>

                <FormGroup sx={{ mb: 3 }}>
                    <InputLabel>Password</InputLabel>
                    <Input type="password" placeholder="Password" ref={ passwordInput } />
                </FormGroup>

                <Button color="primary" type="submit" size="large" variant="contained">
                    Login
                </Button>
            </form>
        </Container>
    );
}