import { createContext } from 'react';

type SearchContextStateType = {
    text: string
    updateSearch: (state: string) => void
  };

const initialContextState: SearchContextStateType = { 
  text: "", 
  updateSearch: () => {},
};
  
export const SearchContext = createContext<SearchContextStateType>(initialContextState);  