import React, { useEffect, useRef } from 'react'
import './Abc.css'
import abcjs from 'abcjs/midi'
import 'abcjs/abcjs-midi.css';
import styled from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import Box from '@mui/material/Box';

// https://configurator.abcjs.net/
type Props = {
  abcNotation: string,
  parserParams: object,
  engraverParams: object,
  renderParams: object
}

const Paper = styled.div`
  overflow-x: scroll !important;
`;

const Container = styled.section`
  text-align: center;
`;

// https://abcnotation.com/wiki/abc:standard:v2.1#multiple_voices
export const AbcViewer = (props: Props) => {

  const noteSpan = useRef<HTMLDivElement>(null);

  useEffect(()=>{
    const tunes = abcjs.renderAbc(
      'paper',
      props.abcNotation,
      {
        add_classes: true,
        warnings_id: "warnings-id",
        responsive: "resize",
      },
    );

    abcjs.renderMidi(
        'audio-control',
        props.abcNotation,
        {
            inlineControls: {
                tempo: true,
            },
            add_classes: true,
            animate: { listener: function(_previousEvent: any, _currentEvent: any, _context: any) {
              const startChar = _currentEvent.startChar;
              const endChar = _currentEvent.endChar;
              let nota = props.abcNotation.substring(startChar,endChar);
              if (nota.length < props.abcNotation.length) {
                noteSpan.current.innerHTML = nota;
              } else {
                noteSpan.current.innerHTML = "";
              }
              if (_currentEvent.elements.length > 0 && _currentEvent.elements[0][0].tagName === 'path') {
                _currentEvent.elements[0][0].setAttribute("fill", "#1976d2") 
              }
              if (null != _previousEvent && _previousEvent.elements.length > 0 && _previousEvent.elements[0][0].tagName === 'path') {
                _previousEvent.elements[0][0].setAttribute("fill", "#000000") 
              }
            }, target: tunes[0], qpm: 120 }
        },
    )
    //document.querySelector(".abcjs-midi-start").onClick();

  }, [props.abcNotation])
  
  return (
    <Container>
      <div id="warnings-id" />
      <Paper id={'paper'} />
      <Box id="audio-control" />
      <span ref={noteSpan}></span>
    </Container>
  )
}