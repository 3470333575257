import React, { useEffect, useRef, useState } from "react";
import { Login } from "../Login/Login";
import Menu from "../Menu/Menu";
import { AbcViewer } from '../atoms/Abcjs'
import { Song } from "../../model/Song";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Box, Container, Grid } from "@mui/material";
import 'abcjs/abcjs-midi.css';
import ChordProViewer from "../atoms/ChordProViewer";
import { deleteChords, duplicateChords, magicWand, saveSong, songify, viewSong } from "../../services/SongServiceBis";
import { isUserAuthenticated, userHasRole } from "../../services/AuthServiceBis";

type Props = {
    abc?: boolean,
    bucket?: string,
    artist?: string,
    title?: string
    useGregorianChords?: boolean
};

type ConfirmButton = {
    label: string,
    onClick: any
}

export default function EditSong(props: Props) {
    const params = useParams();
    const navigate = useNavigate();
    const [selectedText, setSelectedText] = useState(undefined)
    let [userText, setUserText] = useState('');
    let [song, setSong] = useState({ title: "", 
        artist: "", 
        raw: "{title: }\n{artist: }\n", 
        slug: "", 
        bucket: props.bucket, 
        abc: false, 
        lastUpdate: new Date().getTime(),
        tags: [], 
        chords: [] 
    });

    const bucket = props.bucket? props.bucket : params.bucket; 
    const title = props.title? props.title : params.title; 
    const artist = props.artist? props.artist : params.artist; 
    const abc = props.abc? props.abc : params.abc ? true : false; 
    const useGregorianChords = props.useGregorianChords ? props.useGregorianChords : false
    
    const refTextArea = useRef(null);
    let onChangeTimeout: NodeJS.Timer;
    const ON_CHANGE_TIMEOUT: number = 2500;

    const retrieveSong = () => {
        viewSong(bucket, artist, title)
            .then((song: Song) => {
                setSong(song)
                setUserText(song.raw)
            })
            .catch((err: any) => console.log('Request Failed', err));
    }

    useEffect(()=>{
        retrieveSong();   
    // eslint-disable-next-line
    },[ bucket, artist, title ])

    const magicApi = (text: string) => {
        magicWand(bucket, text)
            .then((song: Song) => {
                setSong(song)
                setUserText(song.raw);
            })
            .catch((err: any) => {
                handleError(err, "Error")
            });
    }

    const onSave = (e: React.MouseEvent<MouseEvent>): any => {
        e.preventDefault();
        clearTimeout(onChangeTimeout);
        let text = refTextArea.current.value;
        if (undefined !== text) {
            saveSongApi(text);
        }
    }

    const onMagicWand = (e: React.MouseEvent<MouseEvent>): any => {
        e.preventDefault();
        clearTimeout(onChangeTimeout);
        let text = refTextArea.current.value;
        if (undefined !== text) {
            magicApi(text);
        }
    }

    const getSelectedText = () => {
        let startSelecttion = refTextArea.current.selectionStart;
        let endSelection = refTextArea.current.selectionEnd;
        let text = refTextArea.current.value;
        return text.substring(startSelecttion, endSelection);
    }

    const onCopyChords = (e: React.MouseEvent<MouseEvent>) => {
        let selectedText = getSelectedText();
        setSong(song);
        setSelectedText(selectedText);
    }

    const onPasteChords = (e: React.MouseEvent<MouseEvent>) => {
        clearTimeout(onChangeTimeout);
        let textWithoutChords = getSelectedText();
        duplicateChords(selectedText, textWithoutChords)
            .then((m: any)=>{
                if (m.outcome) {
                    let text = refTextArea.current.value;
                    let newTextWithChords = m.message;
                    text = text.replace(textWithoutChords, newTextWithChords);
                    song.raw = text;
                    setSong(song);
                    setUserText(song.raw);
                    setSelectedText(selectedText);
                } else {
                    throw new Error(m.message);
                }
            })
            .catch((err: any)=>confirmMessage('Error!', err.message))
    }

    const onClearChords = (e: React.MouseEvent<MouseEvent>) => {
        clearTimeout(onChangeTimeout);
        let textWithChords = getSelectedText();
        deleteChords(textWithChords)
            .then((m: any)=>{
                if (m.outcome) {
                    let text = refTextArea.current.value;
                    let textWithoutChords = m.message;
                    text = text.replace(textWithChords, textWithoutChords);
                    song.raw = text;
                    setSong(song);
                    setUserText(song.raw);
                } else {
                    throw new Error(m.message);
                }
                setSelectedText(null);
            })
            .catch((err: any)=>confirmMessage('Error!', err.message))
    }

    const confirmMessage = (title: string, message: string, buttons?: Array<ConfirmButton>) => {
        let btns = [
            {
                label: 'Ok',
                onClick: () => {  }
            }
        ]
        if (undefined !== buttons) {
            btns = buttons;
        }
        confirmAlert({
            title: title,
            message: message,
            buttons: btns
        });
    }

    const isBucketSongEmpty = (): boolean => {
        return (typeof song.bucket === "string" && "" === song.bucket) ||
                undefined === song.bucket || null === song.bucket
                
    }

    const getBucketName = (): string => {
        return isBucketSongEmpty() ? bucket : song.bucket;
    } 

    const createSongApi = (text: string) => {
        let bucketName = getBucketName();
        songify(bucketName, text)
            .then((song: Song) => { 
                setSong(song);
            })
            .catch((err: any) => { 
                console.log("error", err)
            });
    }

    const saveSongApi = (text: string) => {
        saveSong(bucket, text)
            .then(saveMessage => { 
                if (saveMessage.result) {
                    confirmMessage('Saved!', 'Song successfully saved!')
                }
            })
            .catch(err => handleError(err, "Saving Error"));
    }

    const handleError = (err: any, title: string, message?: string): void => {
        if (err.status === 401) {
            confirmMessage('Error', "Unauthorized", [
                {
                    label: 'Close',
                    onClick: () => {  }
                },
                {
                    label: 'Go to Login Page',
                    onClick: () => { navigate("/login") }
                }
            ])
        }
    }

    const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>): any => {
        clearTimeout(onChangeTimeout);
        let text = e.target.value;
        song.raw = text;
        setUserText(text);
        setSong(song)
        onChangeTimeout = setTimeout(()=>createSongApi(text), ON_CHANGE_TIMEOUT)
    };

    const getTextArea = () => {
        return <textarea
                className="form-control"
                id="formRawText"
                ref={refTextArea}
                rows={20}
                value={userText}
                style={{ width:"100%", height:"100vh" }}
                onChange={onChangeText} />
    }

    const getSelectedTextArea = () => {
        let selectedTextArea = null;
        if (selectedText != null) {
            selectedTextArea = <Grid item sx={{ mt: 2 }}>
                                <fieldset className="border-3 border-secondary p-2">
                                    <legend className="float-none w-auto px-3">
                                        <small>Chords to be copied</small>
                                    </legend>
                                    {selectedText}
                                </fieldset>
                            </Grid>
        }
        return selectedTextArea;
    }

    const isAbcSong = (): boolean => {
        return (abc || params.abc !== undefined || song.abc === true);
    };

    const getMainView = () => {
        let view = <ChordProViewer song={song}  showChords={false} useGregorianChords={useGregorianChords} />
        if (isAbcSong()) {
            view = <div>
                    <AbcViewer 
                        abcNotation={song.raw}
                        parserParams={{}}
                        engraverParams={{ responsive: 'resize' }}
                        renderParams={{ viewportHorizontal: true }}
                        />
                </div>
        }
        return view;
    }

    const getMenu = () => {
        let menu = <Menu bucket={bucket} 
                            onDelete={song.slug}
                            onSave={onSave}
                            onMagicWand={onMagicWand}
                            onCopyChords={onCopyChords}
                            onPasteChords={onPasteChords} 
                            onClearChords={onClearChords} />
        if (isAbcSong()) {
            menu = <Menu bucket={bucket} 
                            abc={abc} 
                            onDelete={song.slug}
                            onSave={onSave} />
        }
        return menu;
    }

    if (!window.location.href.endsWith("/new") && (!isUserAuthenticated() || !userHasRole("ROLE_EDITOR"))) {
        return <Login redirect='/' />
    }

    return (<>
            {getMenu()}
            <Container style={{marginTop:"20vh"}}>
                <Box sx={{ mt: 4 }}>
                    Song Slug: <small>{song.slug}</small>
                </Box>
                {getSelectedTextArea()}
                <Box sx={{ mt: 2 }}>
                    {song.tags?.map((e, i)=><Badge key={i} className="me-1">{e}</Badge>)}
                </Box>
                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} md={5}>
                        {getTextArea()}
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} md={6} style={{ padding: "2pt" }}>
                        {getMainView()}
                    </Grid>
                </Grid>
            </Container>
            </>);
}
