import { useIndexedDB } from "react-indexed-db";
import { Song } from "../model/Song";
import { SongHead } from "../model/SongHead";
import ILocalDatabase from "./ILocalDatabase";

const SONGS = 'songs';
const LocalDatabase = (): ILocalDatabase => {
    const { add, getByIndex, getAll } = useIndexedDB(SONGS);

    return {
        addSongToLocalStorage(song: Song): void {
            add(song)
                .then(success => {
                    console.log(song.slug + ' successfully saved')
                }, error => {
                    console.log(song.slug + ' NOT saved')
                })
        },

        async getSongsFromLocalStorage(): Promise<SongHead[]> {
            let songs = await getAll();
            songs.forEach(s=>s.isLocal = true)
            return Promise.resolve(songs);
        },

        async isSlugPresent(slug: string): Promise<Boolean> { 
            let song: Song = await getByIndex('slug', slug);
            return Promise.resolve(song !== null);
        },

        async isSongNewer(song: Song): Promise<Boolean> {
            let dbSong: Song = await getByIndex('slug', song.slug);
            return Promise.resolve(song.lastUpdate > dbSong.lastUpdate);
        },

        async getSong(bucket: string, title: string, artist: string): Promise<Song> {
            let slug = bucket + '/' + artist + '/' + title;
            let song: Song = await getByIndex('slug', slug);
            return Promise.resolve(song);
        }
    }
}

export default LocalDatabase;