// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
	Document   : basic-page-layout
	Created on : Jan, 2010
	Author     : Buz
	Description:
			Page structure and elements not unique or required by UkeGeeks.
*/

/* HTML5 love*/
header,footer,section,article,aside,canvas{display:block;}
/*bsic html */
body {
	font-size:12pt;
	background-color: #DCDCDC;
	/* padding:1.5em; */
}
/* HEADER
  ------------------------------ */
header hgroup aside{
	float:right;
	font-size:.8em;
	width:auto;
}
section {
	background-color: white;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	padding: 20pt 20pt;
	border-radius: 6px;
	min-height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/component/ViewSong/ViewSong.css"],"names":[],"mappings":"AAAA;;;;;;CAMC;;AAED,cAAc;AACd,2CAA2C,aAAa,CAAC;AACzD,aAAa;AACb;CACC,cAAc;CACd,yBAAyB;CACzB,mBAAmB;AACpB;AACA;kCACkC;AAClC;CACC,WAAW;CACX,cAAc;CACd,UAAU;AACX;AACA;CACC,uBAAuB;CACvB,uCAAuC;CACvC,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;CAClB,gBAAgB;AACjB","sourcesContent":["/* \n\tDocument   : basic-page-layout\n\tCreated on : Jan, 2010\n\tAuthor     : Buz\n\tDescription:\n\t\t\tPage structure and elements not unique or required by UkeGeeks.\n*/\n\n/* HTML5 love*/\nheader,footer,section,article,aside,canvas{display:block;}\n/*bsic html */\nbody {\n\tfont-size:12pt;\n\tbackground-color: #DCDCDC;\n\t/* padding:1.5em; */\n}\n/* HEADER\n  ------------------------------ */\nheader hgroup aside{\n\tfloat:right;\n\tfont-size:.8em;\n\twidth:auto;\n}\nsection {\n\tbackground-color: white;\n\tbox-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);\n\ttransition: 0.3s;\n\tpadding: 20pt 20pt;\n\tborder-radius: 6px;\n\tmin-height: 60px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
