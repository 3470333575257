import { ListItem } from "@mui/material";
import React from "react";
import { isUserAuthenticated } from "../../../../services/AuthServiceBis";
import DownloadBucketIcon from "./DownloadBucketIcon";

export const DownloadBucketButton = (props: {
    bucket: string
}) => {
    return isUserAuthenticated() &&
        <ListItem>
            <DownloadBucketIcon fab={true} bucket={props.bucket} />
        </ListItem> 
}

