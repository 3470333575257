export const guitarChords = {
    "A": {frets: [0, 0, 2, 2, 2, 0]},
    "Am": {frets: [ -1, 0, 2, 2, 1, 0]},
    "A7": {frets: [0, 0, 2, 0, 2, 0]},
    "Am7": {frets: [0, 0, 2, 0, 1, 0]},
    "Adim": {frets: [5, 6, 7, 5, 0, 0]},
    "Aaug": {frets: [0, 0, 3, 2, 2, 1]},
    "Asus4": {frets: [0, 0, 2, 2, 3, 0]},
    "A4": {frets: [0, 0, 2, 2, 3, 0]},
    "Asus": {frets: [0, 0, 2, 2, 3, 0]},
    "A6": {frets: [0, 0, 2, 2, 2, 2]},
    "Amaj7": {frets: [0, 0, 2, 1, 2, 0]},
    "A9": {frets: [0, 0, 2, 4, 2, 3]},
    "Aadd9": {frets: [0, 0, 2, 4, 2, 0]},
    "Am6": { frets: [0, 0, 2, 2, 1, 2 ]},
    "Ammaj7": {frets: [ 0,0, 2, 1, 1, 0 ]},
    "Am9": {frets: [ 0, 12, 10, 12, 12, 0 ]},
    "A11": {frets: [ -1, 0, 0, 0, 0, 0 ]},
    "A7sus4": {frets: [ 0, 0, 2, 0, 3, 0 ]},
    "A13": {frets: [ 0, 0, 5, 4, 2, 2 ]},
    "A6add9": {frets: [ 0, 12, 10, 10, 12, 12 ]},
    "A5": {frets: [ 0, 0, 7, 6, 4, 5 ]},
    "A7-5": {frets: [ 0, 0, 1, 0, 2, 0 ]},
    "A7maj5": {frets: [ 0, 0, 3, 0, 2, 1 ]},
    "Amaj9": {frets: [ 5, 4, 6, 4, 5, 0 ]},
    "A/C#": {frets: [ 0, 5, 3, 3, 3, 0 ]},
    "A/F": {frets: [ 1, 0, 2, 2, 2, 0 ]},
    "A/F#": {frets: [ 2, 0, 2, 2, 2, 0 ]},
    "A/G": {frets: [ 3, 0, 2, 2, 2, 0 ]},
    "A/G#": {frets: [ 4, 0, 2, 2, 2, 0 ]},
    "Am/C": {frets: [ 0, 3, 2, 2, 1, 0 ]},
    "Am/F": {frets: [ 1, 0, 2, 2, 1, 0 ]},
    "Am/G": {frets: [ 3, 0, 2, 2, 1, 0 ]},

    "A#": {frets: [ -1, 1, 3, 3, 3, 1 ]},

    "Bb": {frets: [ -1, 1, 3, 3, 3, 1 ]},
    "Bbm": {frets: [ -1, 1, 3, 3, 2, 1 ]},
    "Bb7": {frets: [ 1, 1, 3, 1, 3, 1 ]},
    "Bbm7": {frets: [ 1, 1, 3, 1, 2, 1 ]},
    "Bbdim": {frets: [ 6, 7, 8, 6, -1, -1]},
    "Bbaug": {frets: [ 6, 5, 4, 7, -1, -1]},
    "Bbsus": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "Bbsus4": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "Bb4": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "Bb6": {frets: [ -1, -1, 8, 7, 8, 6 ]},
    "Bbmaj7": {frets: [ 1, 1, 3, 2, 3, 1 ]},
    "Bb9": {frets: [ -1, 1, 0, 1, 1, -1 ]},
    "Bbadd9": {frets: [ -1, 1, 3, 5, 3, 1 ]},
    "Bbm6": {frets: [ 6, 8, 8, 6, 8, 6 ]},
    "Bbmmaj7": {frets: [ -1, 1, 3, 2, 2, 1 ]},
    "Bbm9": {frets: [ 6, 8, 6, 6, 6, 8 ]},
    "Bb11": {frets: [ 6, 8, 6, 8, 6, 8 ]},
    "Bb7sus4": {frets: [ 6, 8, 6, 8, 6, 6 ]},
    "Bb13": {frets: [ 6, -1, 6, 5, 3, 3 ]},
    "Bb6add9": {frets: [ -1, 1, 0, 0, 1, 1 ]},
    "Bbmaj5": {frets: [ -1, 1, 4, 3, 3, 2 ]},
    "Bb7maj5": {frets: [ -1, 1, 4, 1, 3, 2 ]},
    "Bbmaj9": {frets: [ -1, 1, 0, 2, 1, 1 ]},
        
    "B": {frets: [ 2, 2, 4, 4, 4, 2 ]},
    "Bm": {frets: [ 2, 2, 4, 4, 3, 2 ]},
    "B7": {frets: [ -1, 2, 1, 2, 0, 2 ]},
    "Bm7": {frets: [ -1, 2, 4, 2, 3, 2 ]},
    "Bdim": {frets: [ 7, 8, 9, 7 -1, -1]},
    "Baug": {frets: [ 7, 6, 5, 0, 0, -1 ]},
    "Bsus4": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "Bsus": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "B4": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "B6": {frets: [ -1, 2, 1, 1, 0, 2 ]},
    "Bmaj7": {frets: [ -1, 2, 4, 3, 4, 2 ]},
    "B9": {frets: [ -1, 2, 1, 2, 2, -1 ]},
    "Badd9": {frets: [ -1, 2, 4, 6, 4, 2 ]},
    "Bm6": {frets: [ 7, 9, 9, 7, 9, 7 ]},
    "B11": {frets: [ 7, 9, 7, 9, 7, 9 ]},
    "B13": {frets: [ 7, -1, 7, 6, 4, 4 ]},
    "B7maj5": {frets: [ -1, 2, 5, 2, 4, 3 ]},
    "Bmaj9": {frets: [ 7, 6, 8, 6, 7, -1 ]},

    "C": {frets: [ -1, 3, 2, 0, 1, 0 ]},
    "Cm": {frets: [ 3, 3, 5, 5, 4, 3 ]},
    "C7": {frets: [ 0, 3, 2, 3, 1, 0 ]},
    "Cmaj7": {frets: [ 0, 4, 3, 0, 0, 0 ]},
    "C/G": {frets: [ 4, 4, 3, 0, 1, 0 ]},
    "C/E": {frets: [ 0, 4, 3, 0, 1, 0 ]},
    "Cdim": {frets: [ -1, 3, 4, 5, 4, -1 ]},
    "Caug": {frets: [ -1, 4, 3, 1, 1, 0 ]},
    "C6": {frets: [ -1, 4, 3, 3, 1, 0 ]},
    "Csus": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "Csus4": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "C4": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "Cm6": {frets: [ 8, 10, 10, 8, 10, 8 ]},
    "Cm7": {frets: [ -1, 3, 5, 3, 4, 3 ]},
    "C9": {frets: [ -1, 3, 2, 3, 3, 0 ]},
    "Cadd9": {frets: [ -1, 3, 0, 0, 1, 0 ]},
    "C11": {frets: [ -1, 3, -1, 3, 3, 1 ]},
    "C13": {frets: [ -1, 3, 2, 3, 3, 5 ]},
    "C7maj5": {frets: [ -1, 3, 6, 3, 5, 4 ]},
    "Cmaj9": {frets: [ 8, 7, 9, 7, 8, -1 ]},

    "C#": {frets: [ -1, 4, 6, 6, 6, 4 ]},
    "C#m": {frets: [ -1, 4, 6, 6, 5, 4 ]},
    "C#7": {frets: [ -1, 4, 6, 4, 6, 4 ]},
    "C#m7": {frets: [ -1, 4, 6, 4, 5, 4 ]},
    "C#maj7": {frets: [ 1, 5, 4, 1, 1, 1 ]},
    "C#dim": {frets: [ -1, 4, 5, 6, 5, -1 ]},
    "C#aug": {frets: [ -1, 4, 3, 2, 2, -1 ]},
    "C#6": {frets: [ -1, 5, 4, 4, 2, 1 ]},
    "C#sus": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "C#sus4": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "C#4": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "C#m6": {frets: [ 9, 11, 11, 9, 11, 9 ]},
    "C#9": {frets: [ -1, 4, 3, 4, 4, -1 ]},
    "C#add9": {frets: [ -1, 4, 1, 1, 2, 1 ]},
    "C#11": {frets: [ -1, 4, -1, 4, 4, 2 ]},
    "C#13": {frets: [ -1, 4, 3, 4, 4, 6 ]},
    "C#7maj5": {frets: [ -1, 4, 7, 4, 6, 5 ]},
    "C#maj9": {frets: [ 9, 8, 10, 8, 9, -1 ]},

    "D": {frets: [ -1, -1, 0, 2, 3, 2 ]},
    "Dsus2": {frets: [ -1, -1, 0, 2, 3, 0 ]},
    "Dm": {frets: [ -1, -1, 0, 2, 3, 1 ]},
    "Dm6": {frets: [ -1, -1, 0, 2, 0, 1]},
    "D7": {frets: [ -1, -1, 0, 2, 1, 2 ]},
    "Dm7": {frets: [ -1, -1, 0, 2, 1, 1 ]},
    "D9": {frets: [ -1, -1, 0, 2, 1, 0 ]},
    "D11": {frets: [ -1, -1, 0, 5, 5, 3 ]},
    "Ddim": {frets: [ -1, -1, 0, 1, 3, 1 ]},
    "Daug": {frets: [ -1, -1, 0, 3, 3, 2 ]},
    "D6": {frets: [ -1, -1, 0, 2, 0, 2 ]},
    "Dsus": {frets: [ -1, -1, 0, 2, 3, 3 ]},
    "Dmaj7": {frets: [ -1, -1, 0, 2, 2, 2 ]},
    "Dadd9": {frets: [ -1, 5, 4, 2, 3, 0 ]},
    "D13": {frets: [ -1, 5, 4, 5, 5, 7 ]},
    "D7maj5": {frets: [ -1, 5, 8, 5, 7, 6 ]},
    "Dmaj9": {frets: [ 10, 9, 11, 9, 10, -1 ]},
    "Dmaj5": {frets: [ -1, -1, 0, 3, 3, 2] },
    "D6/9": {frets: [ -1, -1, 0, 4, 3, 2 ]},

    "D#": {frets: [ -1, 6, 5, 3, 4, 3 ]},

    "Eb": {frets: [ -1, 6, 5, 3, 4, 3 ]},
    "Ebmaj7": {frets: [ -1, -1, 1, 3, 3, 3 ]},
    "Ebm": {frets: [ -1, 6, 8, 8, 7, 6 ]},
    "Ebdim": {frets: [ -1, 6, 7, 8, 7, -1 ]},
    "Ebaug": {frets: [ -1, 6, 5, 4, 4, -1 ]},
    "Ebsus": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "Ebsus4": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "Eb4": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "Eb7": {frets: [ -1, -1, 1, 3, 2, 3 ]},
    "Eb6": {frets: [ -1, -1, 1, 3, 1, 3 ]},
    "Eb9": {frets: [ -1, 6, 5, 6, 6, -1 ]},
    "Eb11": {frets: [ -1, 6, -1, 6, 6, 4 ]},
    "Eb13": {frets: [ -1, 6, 5, 6, 6, 8 ]},
    "Ebm7": {frets: [ -1, -1, 1, 3, 2, 2 ]},
    "Ebm6": {frets: [ -1, -1, 1, 3, 1, 2 ]},
    "Ebm9": {frets: [ -1, 6, 4, 6, 6, -1 ]},
    "Ebmmaj7": {frets: [ -1, -1, 1, 3, 3, 2 ]},
    "Ebmaj9": {frets: [ -1, -1, 1, 0, 3, 1 ]},

    "E": {frets: [ 0, 2, 2, 1, 0, 0 ]},
    "Em": {frets: [ 0, 2, 2, 0, 0, 0 ]},
    "E7": {frets: [ 0, 2, 2, 1, 3, 0 ]},
    "Em7": {frets: [ 0, 2, 2, 0, 3, 0 ]},
    "Em6": {frets: [ 0, 2, 2, 0, 2, 0 ]},
    "Edim": {frets: [ -1, 7, 8, 9, 8, -1 ]},
    "Eaug": {frets: [ 0, 3, 2, 1, 1, 0 ]},
    "Esus": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "Esus4": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "E4": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "E6": {frets: [ 0, 2, 2, 1, 2, 0 ]},
    "E9": {frets: [ -1, 7, 6, 7, 7, -1 ]},
    "E11": {frets: [ 0, 2, 2, 2, 3, 2 ]},
    "E6add9": {frets: [ -1, 7, 6, 6, 7, 7 ]},
    "E13": {frets: [ -1, 7, 6, 7, 7, 9 ]},
    "Emaj7": {frets: [ 0, 2, 2, 4, 4, 4 ]},
    "Em9": {frets: [ -1, 7, 5, 7, 7, 0 ]},
// F
// -------------------------------------------------------
    "F": {frets: [ 1, 3, 3, 2, 1, 1 ]},
    "Fm": {frets: [ 1, 3, 3, 1, 1, 1 ]},
    "F7": {frets: [ 1, 3, 1, 2, 1, 1 ]},
    "Fm7": {frets: [ 1, 3, 3, 1, 4, 1 ]},
    "Fm6": {frets: [ 1, 3, 3, 1, 3, 1 ]},
    "Fdim": {frets: [ -1, 8, 9, 10, 9, -1 ]},
    "Faug": {frets: [ -1, 8, 7, 6, 6, -1 ]},
    "Fsus": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "Fsus4": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "F4": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "F6": {frets: [ -1, -1, 3, 2, 3, 1 ]},
    "F9": {frets: [ -1, 8, 7, 8, 8, -1 ]},
    "F11": {frets: [ 1, 3, 1, 3, 1, 3 ]},
    "F6add9": {frets: [ -1, 8, 7, 7, 8, 8 ]},
    "F13": {frets: [ -1, 8, 7, 8, 8, 10 ]},
    "Fmaj7": {frets: [ -1, -1, 3, 2, 1, 0 ]},
    "F7+": {frets: [ -1, -1, 3, 2, 1, 0 ]},
    "Fm9": {frets: [ -1, 8, 6, 8, 8, -1 ]},

    "F#": {frets: [ 2, 4, 4, 3, 2, 2 ]},
    "F#m": {frets: [ 2, 4, 4, 2, 2, 2 ]},
    "F#7": {frets: [ 2, 4, 2, 3, 2, 2 ]},
    "F#m7": {frets: [ 2, 4, 4, 2, 5, 2 ]},
    "F#m6": {frets: [ 2, 4, 4, 2, 4, 2 ]},
    "F#dim": {frets: [ -1, 9, 10, 11, 10, -1 ]},
    "F#aug": {frets: [ -1, 9, 8, 7, 7, -1 ]},
    "F#sus": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "F#sus4": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "F#4": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "F#6": {frets: [ -1, -1, 4, 3, 4, 2 ]},
    "F#9": {frets: [ -1, 9, 8, 9, 9, -1 ]},
    "F#11": {frets: [ 2, 4, 2, 4, 2, 4 ]},
    "F#6add9": {frets: [ -1, 9, 8, 8, 9, 9 ]},
    "F#13": {frets: [ -1, 9, 8, 9, 9, 11 ]},
    "F#maj7": {frets: [ -1, -1, 4, 3, 2, 1 ]},
    "F#m9": {frets: [ -1, 9, 7, 9, 9, -1 ]},
    // Gb returns F#
    // G
    // -------------------------------------------------------
    "G": {frets: [ 3, 2, 0, 0, 0, 3 ]},
    "Gm": {frets: [ 3, 5, 5, 3, 3, 3 ]},
    "G7": {frets: [ 3, 2, 0, 0, 0, 1 ]},
    "Gm7": {frets: [ 3, 5, 5, 3, 6, 3 ]},
    "Gm6": {frets: [ 3, 5, 5, 3, 5, 3 ]},
    "Gdim": {frets: [ -1, 10, 11, 12, 11, -1 ]},
    "Gaug": {frets: [ -1, 10, 9, 8, 8, -1 ]},
    "Gsus": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "Gsus4": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "G4": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "G6": {frets: [ -1, -1, 5, 4, 5, 3 ]},
    "G9": {frets: [ -1, 10, 9, 10, 10, -1 ]},
    "G11": {frets: [ 3, 5, 3, 5, 3, 5 ]},
    "G6add9": {frets: [ -1, 10, 9, 9, 10, 10 ]},
    "G13": {frets: [ -1, 10, 9, 10, 10, 12 ]},
    "Gmaj7": {frets: [ -1, -1, 5, 4, 3, 2 ]},
    "Gm9": {frets: [ -1, 10, 8, 10, 10 -1 ]},
    // G#
    // -------------------------------------------------------
    "G#": {frets: [ 4, 6, 6, 5, 4, 4 ]},
    "G#m": {frets: [ 4, 6, 6, 4, 4, 4 ]},
    "G#7": {frets: [ 4, 6, 4, 5, 4, 4 ]},
    "G#m7": {frets: [ 4, 6, 6, 4, 7, 4 ]},
    "G#m6": {frets: [ 4, 6, 6, 4, 6, 4 ]},
    "G#dim": {frets: [ -1, 11, 12, 13, 12, -1 ]},
    "G#aug": {frets: [ -1, 11, 10, 9, 9, -1 ]},
    "G#sus": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "G#sus4": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "G#4": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "G#6": {frets: [ -1, -1, 6, 5, 6, 4 ]},
    "G#9": {frets: [ -1, 11, 10, 11, 11 -1 ]},
    "G#11": {frets: [ 4, 6, 4, 6, 4, 6 ]},
    "G#6add9": {frets: [ -1, 11, 10, 10, 11, 11 ]},
    "G#13": {frets: [ -1, 11, 10, 11, 11, 13 ]},
    "G#maj7": {frets: [ -1, -1, 6, 5, 4, 3 ]},
    "G#m9": {frets: [ -1, 11, 9, 11, 11, -1 ]},

    "Ab": {frets: [ 4, 6, 6, 5, 4, 4 ]},

    "LA": {frets: [ 0, 0, 2, 2, 2, 0 ]},
    "LAm": {frets: [ -1, 0, 2, 2, 1, 0 ]},
    "LA7": {frets: [ 0, 0, 2, 0, 2, 0 ]},
    "LAm7": {frets: [ 0, 0, 2, 0, 1, 0 ]},
    "LAdim": {frets: [ 5, 6, 7, 5, 0, 0 ]},
    "LAaug": {frets: [ 0, 0, 3, 2, 2, 1 ]},
    "LAsus4": {frets: [ 0, 0, 2, 2, 3, 0 ]},
    "LA4": {frets: [ 0, 0, 2, 2, 3, 0 ]},
    "LAsus": {frets: [ 0, 0, 2, 2, 3, 0 ]},
    "LA6": {frets: [ 0, 0, 2, 2, 2, 2 ]},
    "LAmaj7": {frets: [ 0, 0, 2, 1, 2, 0 ]},
    "LA9": {frets: [ 0, 0, 2, 4, 2, 3 ] },
    "LAadd9": {frets: [ 0, 0, 2, 4, 2, 0 ]},
    "LAm6": {frets: [0, 0, 2, 2, 1, 2]},
    "LAmmaj7": {frets: [ 0, 0, 2, 1, 1, 0 ]},
    "LAm9": {frets: [ 0, 12, 10, 12, 12, 0 ]},
    "LA11": {frets: [ -1, 0, 0, 0, 0, 0 ]},
    "LA7sus4": {frets: [ 0, 0, 2, 0, 3, 0 ]},
    "LA13": {frets: [ 0, 0, 5, 4, 2, 2 ]},
    "LA6add9": {frets: [ 0, 12, 10, 10, 12, 12 ]},
    "LA-5": {frets: [ 0, 0, 7, 6, 4, 5 ]},
    "LA7-5": {frets: [ 0, 0, 1, 0, 2, 0 ]},
    "LA7maj5": {frets: [ 0, 0, 3, 0, 2, 1 ]},
    "LAmaj9": {frets: [ 5, 4, 6, 4, 5, 0 ]},
    // SIb
    // -------------------------------------------------------
    "SIb": {frets: [ -1, 1, 3, 3, 3, 1 ]},
    "SIbm": {frets: [ -1, 1, 3, 3, 2, 1 ]},
    "SIb7": {frets: [ 1, 1, 3, 1, 3, 1 ]},
    "SIbm7": {frets: [ 1, 1, 3, 1, 2, 1 ]},
    "SIbdim": {frets: [ 6, 7, 8, 6 -1, -1]},
    "SIbaug": {frets: [ 6, 5, 4, 7 -1, -1 ]},
    "SIbsus": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "SIbsus4": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "SIb4": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "SIb6": {frets: [ -1, -1, 8, 7, 8, 6 ]},
    "SIbmaj7": {frets: [ 1, 1, 3, 2, 3, 1 ]},
    "SIb9": {frets: [ -1, 1, 0, 1, 1, -1 ]},
    "SIbadd9": {frets: [ -1, 1, 3, 5, 3, 1 ]},
    "SIbm6": {frets: [ 6, 8, 8, 6, 8, 6 ]},
    "SIbmmaj7": {frets: [ -1, 1, 3, 2, 2, 1 ]},
    "SIbm9": {frets: [ 6, 8, 6, 6, 6, 8 ]},
    "SIb11": {frets: [ 6, 8, 6, 8, 6, 8 ]},
    "SIb7sus4": {frets: [ 6, 8, 6, 8, 6, 6 ]},
    "SIb13": {frets: [ 6 -1, 6, 5, 3, 3 ]},
    "SIb6add9": {frets: [ -1, 1, 0, 0, 1, 1 ]},
    "SIb7maj5": {frets: [ -1, 1, 4, 1, 3, 2 ]},
    "SIbmaj9": {frets: [ -1, 1, 0, 2, 1, 1 ]},

    "SI": {frets: [ 2, 2, 4, 4, 4, 2 ]},
    "SIm": {frets: [ 2, 2, 4, 4, 3, 2 ]},
    "SI7": {frets: [ -1, 2, 1, 2, 0, 2 ]},
    "SIm7": {frets: [ -1, 2, 4, 2, 3, 2 ]},
    "SIdim": {frets: [ 7, 8, 9, 7 -1, -1 ]},
    "SIaug": {frets: [ 7, 6, 5, 0, 0 ,-1 ]},
    "SIsus4": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "SIsus": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "SI4": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "SI6": {frets: [ -1, 2, 1, 1, 0, 2 ]},
    "SImaj7": {frets: [ -1, 2, 4, 3, 4, 2 ]},
    "SI9": {frets: [ -1, 2, 1, 2, 2, -1 ]},
    "SIadd9": {frets: [ -1, 2, 4, 6, 4, 2 ]},
    "SIm6": {frets: [ 7, 9, 9, 7, 9, 7 ]},
    "SI11": {frets: [ 7, 9, 7, 9, 7, 9 ]},
    "SI13": {frets: [ 7, -1, 7, 6, 4, 4 ]},
    "SI7maj5": {frets: [ -1, 2, 5, 2, 4, 3 ]},
    "SImaj9": {frets: [ 7, 6, 8, 6, 7, -1 ]},

    "DO": {frets: [ -1, 3, 2, 0, 1, 0 ]},
    "DOm": {frets: [ 3, 3, 5, 5, 2, 3 ]},
    "DO7": {frets: [ 0, 3, 2, 3, 1, 0 ]},
    "DOmaj7": {frets: [ 0, 4, 3, 0, 0, 0 ]},
    "DO/SOL": {frets: [ 4, 4, 3, 0, 1, 0 ]},
    "DO/MI": {frets: [ 0, 4, 3, 0, 1, 0 ]},
    "DOdim": {frets: [ -1, 3, 4, 5, 4, -1 ]},
    "DOaug": {frets: [ -1, 4, 3, 1, 1, 0 ]},
    "DO6": {frets: [ -1, 4, 3, 3, 1, 0 ]},
    "DOsus": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "DOsus4": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "DO4": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "DOm6": {frets: [ 8, 10, 10, 8, 10, 8 ]},
    "DOm7": {frets: [ -1, 3, 5, 3, 4, 3 ]},
    "DO9": {frets: [ -1, 3, 2, 3, 3, 0 ]},
    "DOadd9": {frets: [ -1, 3, 0, 0, 1, 0 ]},
    "DO11": {frets: [ -1, 3, -1, 3, 3, 1 ]},
    "DO13": {frets: [ -1, 3, 2, 3, 3, 5 ]},
    "DO7maj5": {frets: [ -1, 3, 6, 3, 5, 4 ]},
    "DOmaj9": {frets: [ 8, 7, 9, 7, 8, -1 ]},
    // DO#
    // -------------------------------------------------------
    "DO#": {frets: [ -1, 4, 6, 6, 6, 4 ]},
    "DO#m": {frets: [ -1, 4, 6, 6, 5, 4 ]},
    "DO#7": {frets: [ -1, 4, 6, 4, 6, 4 ]},
    "DO#m7": {frets: [ -1, 4, 6, 4, 5, 4 ]},
    "DO#dim": {frets: [ -1, 4, 5, 6, 5, -1 ]},
    "DO#aug": {frets: [ -1, 4, 3, 2, 2, -1 ]},
    "DO#6": {frets: [ -1, 5, 4, 4, 2, 1 ]},
    "DO#sus4": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "DO#4": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "DO#m6": {frets: [ 9, 11, 11, 9, 11, 9 ]},
    "DO#9": {frets: [ -1, 4, 3, 4, 4, -1 ]},
    "DO#add9": {frets: [ -1, 4, 1, 1, 2, 1 ]},
    "DO#11": {frets: [ -1, 4, -1, 4, 4, 2 ]},
    "DO#13": {frets: [ -1, 4, 3, 4, 4, 6 ]},
    "DO#7maj5": {frets: [ -1, 4, 7, 4, 6, 5 ]},
    "DO#maj9": {frets: [ 9, 8, 10, 8, 9, -1 ]},

    "RE": {frets: [ -1, -1, 0, 2, 3, 2 ]},
    "REm": {frets: [ -1, -1, 0, 2, 3, 1 ]},
    "REm6": {frets: [ -1, -1, 0, 2, 0, 1]},
    "RE7": {frets: [ -1, -1, 0, 2, 1, 2 ]},
    "REm7": {frets: [ -1, -1, 0, 2, 1, 1 ]},
    "RE9": {frets: [ -1, -1, 0, 2, 1, 0 ]},
    "RE11": {frets: [ -1, -1, 0, 5, 5, 3 ]},
    "REdim": {frets: [ -1, -1, 0, 1, 3, 1 ]},
    "REaug": {frets: [ -1, -1, 0, 3, 3, 2 ]},
    "RE6": {frets: [ -1, -1, 0, 2, 0, 2 ]},
    "REsus": {frets: [ -1, -1, 0, 2, 3, 3 ]},
    "REmaj7": {frets: [ -1, -1, 0, 2, 2, 2 ]},
    "REadd9": {frets: [ -1, 5, 4, 2, 3, 0 ]},
    "RE13": {frets: [ -1, 5, 4, 5, 5, 7 ]},
    "RE7maj5": {frets: [ -1, 5, 8, 5, 7, 6 ]},
    "REmaj9": {frets: [ 10, 9, 11, 9, 10, -1 ]},

    "MIb": {frets: [ -1, 7, 6, 3, 4, 3 ]},
    "MIbmaj7": {frets: [ -1, -1, 1, 3, 3, 3 ]},
    "MIbm": {frets: [ -1, 6, 8, 8, 7, 6 ]},
    "MIbdim": {frets: [ -1, 6, 7, 8, 7, -1 ]},
    "MIbaug": {frets: [ -1, 6, 5, 4, 4, -1 ]},
    "MIbsus": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "MIbsus4": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "MIb4": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "MIb7": {frets: [ -1, -1, 1, 3, 2, 3 ]},
    "MIb6": {frets: [ -1, -1, 1, 3, 1, 3 ]},
    "MIb9": {frets: [ -1, 6, 5, 6, 6, -1 ]},
    "MIb11": {frets: [ -1, 6, -1, 6, 6, 4 ]},
    "MIb13": {frets: [ -1, 6, 5, 6, 6, 8 ]},
    "MIbm7": {frets: [ -1, -1, 1, 3, 2, 2 ]},
    "MIbm6": {frets: [ -1, -1, 1, 3, 1, 2 ]},
    "MIbm9": {frets: [ -1, 6, 4, 6, 6, -1 ]},
    "MIbmmaj7": {frets: [ -1, -1, 1, 3, 3, 2 ]},
    "MIbmaj9": {frets: [ -1, -1, 1, 0, 3, 1 ]},

    "MI": {frets: [ 0, 2, 2, 1, 0, 0 ]},
    "MIm": {frets: [ 0, 2, 2, 0, 0, 0 ]},
    "MI7": {frets: [ 0, 2, 2, 1, 3, 0 ]},
    "MIm7": {frets: [ 0, 2, 2, 0, 3, 0 ]},
    "MIm6": {frets: [ 0, 2, 2, 0, 2, 0 ]},
    "MIdim": {frets: [ -1, 7, 8, 9, 8, -1 ]},
    "MIaug": {frets: [ 0, 3, 2, 1, 1, 0 ]},
    "MIsus": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "MIsus4": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "MI4": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "MI6": {frets: [ 0, 2, 2, 1, 2, 0 ]},
    "MI9": {frets: [ -1, 7, 6, 7, 7, -1 ]},
    "MI11": {frets: [ 0, 2, 2, 2, 3, 2 ]},
    "MI6add9": {frets: [ -1, 7, 6, 6, 7, 7 ]},
    "MI13": {frets: [ -1, 7, 6, 7, 7, 9 ]},
    "MImaj7": {frets: [ 0, 2, 2, 4, 4, 4 ]},
    "MIm9": {frets: [ -1, 7, 5, 7, 7, 0 ]},

    "FA": {frets: [ 1, 3, 3, 2, 1, 1 ]},
    "FAm": {frets: [ 1, 3, 3, 1, 1, 1 ]},
    "FA7": {frets: [ 1, 3, 1, 2, 1, 1 ]},
    "FAm7": {frets: [ 1, 3, 3, 1, 4, 1 ]},
    "FAm6": {frets: [ 1, 3, 3, 1, 3, 1 ]},
    "FAdim": {frets: [ -1, 8, 9, 10, 9, -1 ]},
    "FAaug": {frets: [ -1, 8, 7, 6, 6, -1 ]},
    "FAsus": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "FAsus4": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "FA4": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "FA6": {frets: [ -1, -1, 3, 2, 3, 1 ]},
    "FA9": {frets: [ -1, 8, 7, 8, 8, -1 ]},
    "FA11": {frets: [ 1, 3, 1, 3, 1, 3 ]},
    "FA6add9": {frets: [ -1, 8, 7, 7, 8, 8 ]},
    "FA13": {frets: [ -1, 8, 7, 8, 8, 10 ]},
    "FAmaj7": {frets: [ -1, -1, 3, 2, 1, 0 ]},
    "FAm9": {frets: [ -1, 8, 6, 8, 8, -1 ]},

    "FA#": {frets: [ 2, 4, 4, 3, 2, 2 ]},
    "FA#m": {frets: [ 2, 4, 4, 2, 2, 2 ]},
    "FA#7": {frets: [ 2, 4, 2, 3, 2, 2 ]},
    "FA#m7": {frets: [ 2, 4, 4, 2, 5, 2 ]},
    "FA#m6": {frets: [ 2, 4, 4, 2, 4, 2 ]},
    "FA#dim": {frets: [ -1, 9, 10, 11, 10, -1 ]},
    "FA#aug": {frets: [ -1, 9, 8, 7, 7, -1 ]},
    "FA#sus": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "FA#sus4": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "FA#4": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "FA#6": {frets: [ -1, -1, 4, 3, 4, 2 ]},
    "FA#9": {frets: [ -1, 9, 8, 9, 9, -1 ]},
    "FA#11": {frets: [ 2, 4, 2, 4, 2, 4 ]},
    "FA#6add9": {frets: [ -1, 9, 8, 8, 9, 9 ]},
    "FA#13": {frets: [ -1, 9, 8, 9, 9, 11 ]},
    "FA#maj7": {frets: [ -1, -1, 4, 3, 2, 1 ]},
    "FA#m9": {frets: [ -1, 9, 7, 9, 9, -1 ]},

    "SOL": {frets: [ 3, 2, 0, 0, 0, 3 ]},
    "SOLm": {frets: [ 3, 5, 5, 3, 3, 3 ]},
    "SOL7": {frets: [ 3, 2, 0, 0, 0, 1 ]},
    "SOLm7": {frets: [ 3, 5, 5, 3, 6, 3 ]},
    "SOLm6": {frets: [ 3, 5, 5, 3, 5, 3 ]},
    "SOLdim": {frets: [ -1, 10, 11, 12, 11, -1 ]},
    "SOLaug": {frets: [ -1, 10, 9, 8, 8, -1 ]},
    "SOL+": {frets: [ -1, 10, 9, 8, 8, -1 ]},
    "SOLsus": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "SOLsus4": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "SOL4": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "SOL6": {frets: [ -1, -1, 5, 4, 5, 3 ]},
    "SOL9": {frets: [ -1, 10, 9, 10, 10, -1 ]},
    "SOL11": {frets: [ 3, 5, 3, 5, 3, 5 ]},
    "SOL6add9": {frets: [ -1, 10, 9, 9, 10, 10 ]},
    "SOL13": {frets: [ -1, 10, 9, 10, 10, 12 ]},
    "SOLmaj7": {frets: [ -1, -1, 5, 4, 3, 2 ]},
    "SOLm9": {frets: [ -1, 10, 8, 10, 10, -1 ]},
    // SOL#
    // -------------------------------------------------------
    "SOL#": {frets: [ 4, 6, 6, 5, 4, 4 ]},
    "SOL#m": {frets: [ 4, 6, 6, 4, 4, 4 ]},
    "SOL#7": {frets: [ 4, 6, 4, 5, 4, 4 ]},
    "SOL#m7": {frets: [ 4, 6, 6, 4, 7, 4 ]},
    "SOL#m6": {frets: [ 4, 6, 6, 4, 6, 4 ]},
    "SOL#dim": {frets: [ -1, 11, 12, 13, 12, -1 ]},
    "SOL#aug": {frets: [ -1, 11, 10, 9, 9, -1 ]},
    "SOL#sus": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "SOL#sus4": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "SOL#4": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "SOL#6": {frets: [ -1, -1, 6, 5, 6, 4 ]},
    "SOL#9": {frets: [ -1, 11, 10, 11, 11, -1 ]},
    "SOL#11": {frets: [ 4, 6, 4, 6, 4, 6 ]},
    "SOL#6add9": {frets: [ -1, 11, 10, 10, 11, 11 ]},
    "SOL#13": {frets: [ -1, 11, 10, 11, 11, 13 ]},
    "SOL#maj7": {frets: [ -1, -1, 6, 5, 4, 3 ]},
    "SOL#m9": {frets: [ -1, 11, 9, 11, 11, -1 ]},
    // Lab returns Sol#
    // La
    // -------------------------------------------------------
    "La": {frets: [ 0, 0, 2, 2, 2, 0 ]},
    "Lam": {frets: [ -1, 0, 2, 2, 1, 0 ]},
    "La7": {frets: [ 0, 0, 2, 0, 2, 0 ]},
    "Lam7": {frets: [ 0, 0, 2, 0, 1, 0 ]},
    "Ladim": {frets: [ 5, 6, 7, 5, 0, 0 ]},
    "Laaug": {frets: [ 0, 0, 3, 2, 2, 1 ]},
    "Lasus4": {frets: [ 0, 0, 2, 2, 3, 0 ]},
    "La4": {frets: [ 0, 0, 2, 2, 3, 0 ]},
    "Lasus": {frets: [ 0, 0, 2, 2, 3, 0 ]},
    "La6": {frets: [ 0, 0, 2, 2, 2, 2 ]},
    "Lamaj7": {frets: [ 0, 0, 2, 1, 2, 0 ]},
    "La9": {frets: [ 0, 0, 2, 4, 2, 3 ]},
    "Laadd9": {frets: [ 0, 0, 2, 4, 2, 0 ]},
    "Lam6": { frets: [0, 0, 2, 2, 1, 2 ]},
    "Lammaj7": {frets: [ 0, 0, 2, 1, 1, 0 ]},
    "Lam9": {frets: [ 0, 12, 10, 12, 12, 0 ]},
    "La11": {frets: [ -1, 0, 0, 0, 0, 0 ]},
    "La7sus4": {frets: [ 0, 0, 2, 0, 3, 0 ]},
    "La13": {frets: [ 0, 0, 5, 4, 2, 2 ]},
    "La6add9": {frets: [ 0, 12, 10, 10, 12, 12 ]},
    "La-5": {frets: [ 0, 0, 7, 6, 4, 5 ]},
    "La7-5": {frets: [ 0, 0, 1, 0, 2, 0 ]},
    "La7maj5": {frets: [ 0, 0, 3, 0, 2, 1 ]},
    "Lamaj9": {frets: [ 5, 4, 6, 4, 5, 0 ]},

    "Sib": {frets: [ -1,1, 3, 3, 3, 1 ]},
    "Sibm": {frets: [ -1, 1, 3, 3, 2, 1 ]},
    "Sib7": {frets: [ 1, 1, 3, 1, 3, 1 ]},
    "Sibm7": {frets: [ 1, 1, 3, 1, 2, 1 ]},
    "Sibdim": {frets: [ 6, 7, 8, 6, -1, -1]},
    "Sibaug": {frets: [ 6, 5, 4, 7, -1, -1 ]},
    "Sibsus": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "Sibsus4": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "Sib4": {frets: [ -1, 1, 3, 3, 4, 1 ]},
    "Sib6": {frets: [ -1, -1, 8, 7, 8, 6 ]},
    "Sibmaj7": {frets: [ 1, 1, 3, 2, 3, 1 ]},
    "Sib9": {frets: [ -1, 1, 0, 1, 1, -1 ]},
    "Sibadd9": {frets: [ -1, 1, 3, 5, 3, 1 ]},
    "Sibm6": {frets: [ 6, 8, 8, 6, 8, 6 ]},
    "Sibmmaj7": {frets: [ -1, 1, 3, 2, 2, 1 ]},
    "Sibm9": {frets: [ 6, 8, 6, 6, 6, 8 ]},
    "Sib11": {frets: [ 6, 8, 6, 8, 6, 8 ]},
    "Sib7sus4": {frets: [ 6, 8, 6, 8, 6, 6 ]},
    "Sib13": {frets: [ 6, -1, 6, 5, 3, 3 ]},
    "Sib6add9": {frets: [ -1, 1, 0, 0, 1, 1 ]},
    "Sib7maj5": {frets: [ -1, 1, 4, 1, 3, 2 ]},
    "Sibmaj9": {frets: [ -1, 1, 0, 2, 1, 1 ]},
    // Si
    // -------------------------------------------------------
    "Si": {frets: [ 2, 2, 4, 4, 4, 2 ]},
    "Sim": {frets: [ 2, 2, 4, 4, 3, 2 ]},
    "Si7": {frets: [ -1, 2, 1, 2, 0, 2 ]},
    "Sim7": {frets: [ -1, 2, 4, 2, 3, 2 ]},
    "Sidim": {frets: [ 7, 8, 9, 7, -1, -1 ]},
    "Siaug": {frets: [ 7, 6, 5, 0, 0, -1 ]},
    "Sisus4": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "Sisus": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "Si4": {frets: [ -1, 2, 4, 4, 5, 2 ]},
    "Si6": {frets: [ -1, 2, 1, 1, 0, 2 ]},
    "Simaj7": {frets: [ -1, 2, 4, 3, 4, 2 ]},
    "Si9": {frets: [ -1, 2, 1, 2, 2, -1 ]},
    "Siadd9": {frets: [ -1, 2, 4, 6, 4, 2 ]},
    "Sim6": {frets: [ 7, 9, 9, 7, 9, 7 ]},
    "Si11": {frets: [ 7, 9, 7, 9, 7, 9 ]},
    "Si13": {frets: [ 7, -1, 7, 6, 4, 4 ]},
    "Si7maj5": {frets: [ -1, 2, 5, 2, 4, 3 ]},
    "Simaj9": {frets: [ 7, 6, 8, 6, 7, -1 ]},

    "Do": {frets: [ -1, 3, 2, 0, 1, 0 ]},
    "Dom": {frets: [ 3, 3, 5, 5, 2, 3 ]},
    "Do7": {frets: [ 0, 3, 2, 3, 1, 0 ]},
    "Domaj7": {frets: [ 0, 4, 3, 0, 0, 0 ]},
    "Do/Sol": {frets: [ 4, 4, 3, 0, 1, 0 ]},
    "Do/Mi": {frets: [ 0, 4, 3, 0, 1, 0 ]},
    "Dodim": {frets: [ -1, 3, 4, 5, 4, -1 ]},
    "Doaug": {frets: [ -1, 4, 3, 1, 1, 0 ]},
    "Do6": {frets: [ -1, 4, 3, 3, 1, 0 ]},
    "Dosus": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "Dosus4": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "Do4": {frets: [ -1, 3, 3, 0, 1, 1 ]},
    "Dom6": {frets: [ 8, 10, 10, 8, 10, 8 ]},
    "Dom7": {frets: [ -1, 3, 5, 3, 4, 3 ]},
    "Do9": {frets: [ -1, 3, 2, 3, 3, 0 ]},
    "Doadd9": {frets: [ -1, 3, 0, 0, 1, 0 ]},
    "Do11": {frets: [ -1, 3, -1, 3, 3, 1 ]},
    "Do13": {frets: [ -1, 3, 2, 3, 3, 5 ]},
    "Do7maj5": {frets: [ -1, 3, 6, 3, 5, 4 ]},
    "Domaj9": {frets: [ 8, 7, 9, 7, 8, -1 ]},
    // Do#
    // -------------------------------------------------------
    "Do#": {frets: [ -1, 4, 6, 6, 6, 4 ]},
    "Do#m": {frets: [ -1, 4, 6, 6, 5, 4 ]},
    "Do#7": {frets: [ -1, 4, 6, 4, 6, 4 ]},
    "Do#m7": {frets: [ -1, 4, 6, 4, 5, 4 ]},
    "Do#dim": {frets: [ -1, 4, 5, 6, 5, -1 ]},
    "Do#aug": {frets: [ -1,4, 3, 2, 2, -1 ]},
    "Do#6": {frets: [ -1, 5, 4, 4, 2, 1 ]},
    "Do#sus": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "Do#sus4": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "Do#4": {frets: [ -1, 4, 4, 1, 2, 2 ]},
    "Do#m6": {frets: [ 9, 11, 11, 9, 11, 9 ]},
    "Do#9": {frets: [ -1, 4, 3, 4, 4, -1 ]},
    "Do#add9": {frets: [ -1, 4, 1, 1, 2, 1 ]},
    "Do#11": {frets: [ -1, 4, -1, 4, 4, 2 ]},
    "Do#13": {frets: [ -1, 4, 3, 4, 4, 6 ]},
    "Do#7maj5": {frets: [ -1, 4, 7, 4, 6, 5 ]},
    "Do#maj9": {frets: [ 9, 8, 10, 8, 9, -1 ]},

    "Re": {frets: [ -1, -1, 0, 2, 3, 2 ]},
    "Rem": {frets: [ -1, -1, 0, 2, 3, 1 ]},
    "Rem6": {frets: [ -1, -1, 0, 2, 0, 1]},
    "Re7": {frets: [ -1, -1, 0, 2, 1, 2 ]},
    "Rem7": {frets: [ -1, -1, 0, 2, 1, 1 ]},
    "Re9": {frets: [ -1, -1, 0, 2, 1, 0 ]},
    "Re11": {frets: [ -1, -1, 0, 5, 5, 3 ]},
    "Redim": {frets: [ -1, -1, 0, 1, 3, 1 ]},
    "Reaug": {frets: [ -1, -1, 0, 3, 3, 2 ]},
    "Re6": {frets: [ -1, -1, 0, 2, 0, 2 ]},
    "Resus": {frets: [ -1, -1, 0, 2, 3, 3 ]},
    "Remaj7": {frets: [ -1,-1, 0, 2, 2, 2 ]},
    "Readd9": {frets: [ -1, 5, 4, 2, 3, 0 ]},
    "Re13": {frets: [ -1, 5, 4, 5, 5, 7 ]},
    "Re7maj5": {frets: [ -1, 5, 8, 5, 7, 6 ]},
    "Remaj9": {frets: [ 10, 9, 11, 9, 10, -1 ]},

    "Mib": {frets: [ -1, 7, 6, 3, 4, 3 ]},
    "Mibmaj7": {frets: [ -1, -1, 1, 3, 3, 3 ]},
    "Mibm": {frets: [ -1, 6, 8, 8, 7, 6 ]},
    "Mibdim": {frets: [ -1, 6, 7, 8, 7, -1 ]},
    "Mibaug": {frets: [ -1, 6, 5, 4, 4, -1 ]},
    "Mibsus": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "Mibsus4": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "Mib4": {frets: [ -1, 6, 8, 8, 9, 6 ]},
    "Mib7": {frets: [ -1, -1, 1, 3, 2, 3 ]},
    "Mib6": {frets: [ -1, -1, 1, 3, 1, 3 ]},
    "Mib9": {frets: [ -1, 6, 5, 6, 6, -1 ]},
    "Mib11": {frets: [ -1, 6, -1, 6, 6, 4 ]},
    "Mib13": {frets: [ -1, 6, 5, 6, 6, 8 ]},
    "Mibm7": {frets: [ -1, -1, 1, 3, 2, 2 ]},
    "Mibm6": {frets: [ -1, -1, 1, 3, 1, 2 ]},
    "Mibm9": {frets: [ -1, 6, 4, 6, 6, -1 ]},
    "Mibmmaj7": {frets: [ -1, -1, 1, 3, 3, 2 ]},
    "Mibmaj9": {frets: [ -1, -1, 1, 0, 3, 1 ]},

    "Mi": {frets: [ 0, 2, 2, 1, 0, 0 ]},
    "Mim": {frets: [ 0, 2, 2, 0, 0, 0 ]},
    "Mi7": {frets: [ 0, 2, 2, 1, 3, 0 ]},
    "Mim7": {frets: [ 0, 2, 2, 0, 3, 0 ]},
    "Mim6": {frets: [ 0, 2, 2, 0, 2, 0 ]},
    "Midim": {frets: [ -1, 7, 8, 9, 8, -1 ]},
    "Miaug": {frets: [ 0, 3, 2, 1, 1, 0 ]},
    "Misus": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "Misus4": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "Mi4": {frets: [ 0, 2, 2, 2, 0, 0 ]},
    "Mi6": {frets: [ 0, 2, 2, 1, 2, 0 ]},
    "Mi9": {frets: [ -1, 7, 6, 7, 7, -1 ]},
    "Mi11": {frets: [ 0, 2, 2, 2, 3, 2 ]},
    "Mi6add9": {frets: [ -1, 7, 6, 6, 7, 7 ]},
    "Mi13": {frets: [ -1, 7, 6, 7, 7, 9 ]},
    "Mimaj7": {frets: [ 0, 2, 2, 4, 4, 4 ]},
    "Mim9": {frets: [ -1, 7, 5, 7, 7, 0 ]},
    // Fa
    // -------------------------------------------------------
    "Fa": {frets: [ 1, 3, 3, 2, 1, 1 ]},
    "Fam": {frets: [ 1, 3, 3, 1, 1, 1 ]},
    "Fa7": {frets: [ 1, 3, 1, 2, 1, 1 ]},
    "Fam7": {frets: [ 1, 3, 3, 1, 4, 1 ]},
    "Fam6": {frets: [ 1, 3, 3, 1, 3, 1 ]},
    "Fadim": {frets: [ -1, 8, 9, 10, 9, -1 ]},
    "Faaug": {frets: [ -1, 8, 7, 6, 6, -1 ]},
    "Fasus": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "Fasus4": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "Fa4": {frets: [ 1, 3, 3, 3, 1, 1 ]},
    "Fa6": {frets: [ -1, -1, 3, 2, 3, 1 ]},
    "Fa9": {frets: [ -1, 8, 7, 8, 8, -1 ]},
    "Fa11": {frets: [ 1, 3, 1, 3, 1, 3 ]},
    "Fa6add9": {frets: [ -1, 8, 7, 7, 8, 8 ]},
    "Fa13": {frets: [ -1, 8, 7, 8, 8, 10 ]},
    "Famaj7": {frets: [ -1, -1, 3, 2, 1, 0 ]},
    "Fam9": {frets: [ -1, 8, 6, 8, 8, -1 ]},
    // Fa#
    // -------------------------------------------------------
    "Fa#": {frets: [ 2, 4, 4, 3, 2, 2 ]},
    "Fa#m": {frets: [ 2, 4, 4, 2, 2, 2 ]},
    "Fa#7": {frets: [ 2, 4, 2, 3, 2, 2 ]},
    "Fa#m7": {frets: [ 2, 4, 4, 2, 5, 2 ]},
    "Fa#m6": {frets: [ 2, 4, 4, 2, 4, 2 ]},
    "Fa#dim": {frets: [ -1, 9, 10, 11, 10, -1 ]},
    "Fa#aug": {frets: [ -1, 9, 8, 7, 7, -1 ]},
    "Fa#sus": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "Fa#sus4": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "Fa#4": {frets: [ 2, 4, 4, 4, 2, 2 ]},
    "Fa#6": {frets: [ -1, -1, 4, 3, 4, 2 ]},
    "Fa#9": {frets: [ -1, 9, 8, 9, 9, -1 ]},
    "Fa#11": {frets: [ 2, 4, 2, 4, 2, 4 ]},
    "Fa#6add9": {frets: [ -1, 9, 8, 8, 9, 9 ]},
    "Fa#13": {frets: [ -1, 9, 8, 9, 9, 11 ]},
    "Fa#maj7": {frets: [ -1, -1, 4, 3, 2, 1 ]},
    "Fa#m9": {frets: [ -1, 9, 7, 9, 9, -1 ]},
    // Solb returns Fa#
    // Sol
    // -------------------------------------------------------
    "Sol": {frets: [ 3, 2, 0, 0, 0, 3 ]},
    "Solm": {frets: [ 3, 5, 5, 3, 3, 3 ]},
    "Sol7": {frets: [ 3, 2, 0, 0, 0, 1 ]},
    "Solm7": {frets: [ 3, 5, 5, 3, 6, 3 ]},
    "Solm6": {frets: [ 3, 5, 5, 3, 5, 3 ]},
    "Soldim": {frets: [ -1, 10, 11, 12, 11, -1 ]},
    "Solaug": {frets: [ -1, 10, 9, 8, 8, -1 ]},
    "Solsus": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "Solsus4": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "Sol4": {frets: [ 3, 5, 5, 5, 3, 3 ]},
    "Sol6": {frets: [ -1, -1, 5, 4, 5, 3 ]},
    "Sol9": {frets: [ -1, 10, 9, 10, 10, -1 ]},
    "Sol11": {frets: [ 3, 5, 3, 5, 3, 5 ]},
    "Sol6add9": {frets: [ -1, 10, 9, 9, 10, 10 ]},
    "Sol13": {frets: [ -1, 10, 9, 10, 10, 12 ]},
    "Solmaj7": {frets: [ -1, -1, 5, 4, 3, 2 ]},
    "Solm9": {frets: [ -1, 10, 8, 10, 10, -1 ]},
    // Sol#
    // -------------------------------------------------------
    "Sol#": {frets: [ 4, 6, 6, 5, 4, 4 ]},
    "Sol#m": {frets: [ 4, 6, 6, 4, 4, 4 ]},
    "Sol#7": {frets: [ 4, 6, 4, 5, 4, 4 ]},
    "Sol#m7": {frets: [ 4, 6, 6, 4, 7, 4 ]},
    "Sol#m6": {frets: [ 4, 6, 6, 4, 6, 4 ]},
    "Sol#dim": {frets: [ -1, 11, 12, 13, 12, -1 ]},
    "Sol#aug": {frets: [ -1, 11, 10, 9, 9, -1 ]},
    "Sol#sus": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "Sol#sus4": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "Sol#4": {frets: [ 4, 6, 6, 6, 4, 4 ]},
    "Sol#6": {frets: [ -1, -1, 6, 5, 6, 4 ]},
    "Sol#9": {frets: [ -1, 11, 10, 11, 11, -1 ]},
    "Sol#11": {frets: [ 4, 6, 4, 6, 4, 6 ]},
    "Sol#6add9": {frets: [ -1, 11, 10, 10, 11, 11 ]},
    "Sol#13": {frets: [ -1, 11, 10, 11, 11, 13 ]},
    "Sol#maj7": {frets: [ -1, -1, 6, 5, 4, 3 ]},
    "Sol#m9": {frets: [ -1, 11, 9, 11, 11, -1 ]},
    "Lab": {frets: [ 4, 6, 6, 5, 4, 4 ]},
}