import React, { useRef } from "react";
import { Transpose } from "./Transpose";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Fab, ListItem, Tooltip } from "@mui/material";
import { Part } from "../../../Menu/IconMenu";

export const TransposeButton = (props: { 
        transpose?: React.ChangeEventHandler<HTMLSelectElement>
        useGregorianChords?: boolean
     }) => {
    const transposeExpanded = useRef<boolean>(false)
    const transposeBoxRef = useRef(null);

    return <ListItem>
            <Part ref={transposeBoxRef}>
                <Transpose 
                    onChange={props.transpose} 
                    useGregorianChords={props.useGregorianChords} />
            </Part>
            <Fab onClick={() => {
                if (!transposeExpanded.current) {
                    transposeBoxRef.current.parentElement.parentElement.parentElement.style.width = '90%';
                    transposeBoxRef.current.parentElement.style.backgroundColor = 'var(--color-background)';
                    transposeBoxRef.current.parentElement.style.borderRadius = '.5rem';
                    transposeBoxRef.current.style.display = 'block';
                    transposeBoxRef.current.style.width = '100%';
                    transposeExpanded.current = true;
                } else {
                    transposeBoxRef.current.parentElement.parentElement.parentElement.style.width = 'auto';
                    transposeBoxRef.current.parentElement.style.backgroundColor = 'transparent';
                    transposeBoxRef.current.style.display = 'none';
                    transposeBoxRef.current.style.width = 'auto';
                    transposeExpanded.current = false;
                }
            } }>
                <Tooltip title={"transpose"}>
                    <MusicNoteIcon />
                </Tooltip>
            </Fab>
            </ListItem>
}