
import jwt_decode from "jwt-decode";
import { ResponseMessage } from "../model/ResponseMessage";
import { getBaseUrl } from "../getBaseUrl";

const ONE_HOUR = 60 * 60;
let lastRenewedTokenCall: number = parseInt(localStorage.getItem('lastRenewedTokenCall')) || 0;

export const logout = (): void => {
    localStorage.removeItem('authorization-token');
    localStorage.removeItem('authenticated');
}

export const login = async(username: string, password: string): Promise<ResponseMessage> => {
    let url = getBaseUrl() + '/auth/login';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    let message: ResponseMessage = {
        outcome: false,
        message: 'Bad Credentials'
    }
    await fetch(url, requestOptions)            
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => { 
            let token = res.headers.get("Authorization");
            if (null !== token) {
                localStorage.setItem('authorization-token', token)
                
                message.outcome = true;
                message.message = token;
            }
            return res.json()
        })
        .then(user=>{
            localStorage.setItem('authenticated', JSON.stringify(user));
        })
        .catch(err => { 
            console.log('Bad credentials', err)
        });
    return Promise.resolve(message)
}

export const getCurrentUsername = (): string | undefined => {
    const authenticated = localStorage.getItem("authenticated")
    if (null !== authenticated) {
        const obj = JSON.parse(authenticated)
        return obj.username
    }
    return undefined
}

const getUtcMilllisecondsSinceEpoch = (): number => {
    const now = new Date()  
    return now.getTime() + (now.getTimezoneOffset() * 60 * 1000)  
}

const getUtcSecondsTimestamp = (): number => {
    const utcMilllisecondsSinceEpoch = getUtcMilllisecondsSinceEpoch();
    return Math.round(utcMilllisecondsSinceEpoch / 1000)
}

const tokenHasRenewedMoreThanTwelveHourAgo = (): boolean => {
    const now = getUtcSecondsTimestamp();
    return now - lastRenewedTokenCall > (60*60*12);
}

const tokenIsValid = (): boolean => {
    let token = localStorage.getItem('authorization-token');
    if (typeof token === "string") {
        let decoded: any = jwt_decode(token);
        let utcTimestamp = getUtcSecondsTimestamp();
        let isExpired = utcTimestamp >= decoded.exp;
        if (!isExpired) {
            if (decoded.exp <= (utcTimestamp + ONE_HOUR) && tokenHasRenewedMoreThanTwelveHourAgo()) {
                refreshToken(token);
                lastRenewedTokenCall = getUtcSecondsTimestamp();
                localStorage.setItem('lastRenewedTokenCall', lastRenewedTokenCall.toString())
            }
            return true;
        }
    }
    return false;
}

export const userHasRole = (role: any): boolean => {
    let strAuthenticated = localStorage.getItem("authenticated");
    if (null != strAuthenticated) {
        let authUser = JSON.parse(strAuthenticated);
        for (let i=0; i<authUser.authorities.length; i++) {
            let authority = authUser.authorities[i];
            if (authority === role) {
                return true;
            }
        }
    }
    return false;
}

const refreshToken = (token : string): void => {
    let url = getBaseUrl() + '/auth/refresh-token';
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: token
    };
    fetch(url, requestOptions)            
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => { 
            let token = res.headers.get("Authorization");
            if (token !== null) {
                localStorage.setItem('authorization-token', token);
            }
            return res.json() })
        .then(res => {
            localStorage.setItem('authenticated', JSON.stringify(res));
        })
        .catch(err => { 
            console.log('Unable to refresh token', err) 
        }); 
}

export const isUserAuthenticated = (): boolean => {
    try {
        // let userIsAuthenticated = null != localStorage.getItem("authenticated");
        if (localStorageContainsAuthentication()) {
            return tokenIsValid();
        }
    } catch (err) {
        console.log(err);
    }
    return false;
}

const localStorageContainsAuthentication = (): boolean => {
    let authenticated = localStorage.getItem("authenticated")
    return null !== authenticated && undefined !== authenticated && '' !== authenticated && 'null' !== authenticated
}