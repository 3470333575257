// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* song list css */
ul {
    list-style-type:none;
}

#searchBox {
    width: 80%;
}

body {
  background-color: white;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 10px 16px;
  margin: 12px 0px;
  border-radius: 6px;
  min-height: 60px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0px 8px 16px 8px rgba(0,0,0,0.2);
  background-color: rgba(13, 110, 253, 0.2);
  font-weight: 600;
}

.card .artist, .title {
  margin: 0px 5px;
}

.card a {
  text-decoration: none;
}

ul {
  z-index: 2;
  margin-top: 10vh;
}`, "",{"version":3,"sources":["webpack://./src/component/ViewBucket/ViewBucket.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,4CAA4C;EAC5C,uCAAuC;EACvC,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,uCAAuC;AACvC;EACE,4CAA4C;EAC5C,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["/* song list css */\nul {\n    list-style-type:none;\n}\n\n#searchBox {\n    width: 80%;\n}\n\nbody {\n  background-color: white;\n}\n\n.card {\n  /* Add shadows to create the \"card\" effect */\n  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);\n  transition: 0.3s;\n  padding: 10px 16px;\n  margin: 12px 0px;\n  border-radius: 6px;\n  min-height: 60px;\n}\n\n/* On mouse-over, add a deeper shadow */\n.card:hover {\n  box-shadow: 0px 8px 16px 8px rgba(0,0,0,0.2);\n  background-color: rgba(13, 110, 253, 0.2);\n  font-weight: 600;\n}\n\n.card .artist, .title {\n  margin: 0px 5px;\n}\n\n.card a {\n  text-decoration: none;\n}\n\nul {\n  z-index: 2;\n  margin-top: 10vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
