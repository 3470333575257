import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

type Props = {
    onChange: any,
    useGregorianChords?: boolean;
}

const StyledControl = styled(FormControl)`
    label {
        color: var(--color-foreground);
    }
    border: 0px
`;

export const Transpose = (props: Props) => {
        return (
            <StyledControl fullWidth>
                <InputLabel id="demo-simple-select-label">Transpose</InputLabel>
                <Select onChange={props.onChange} defaultValue = "">
                    <MenuItem value="A">{ props.useGregorianChords ? "La" : "A" }</MenuItem>
                    <MenuItem value="Bb">{ props.useGregorianChords ? "La/Sib" : "A#/Bb" }</MenuItem>
                    <MenuItem value="B">{ props.useGregorianChords ? "Si" : "B" }</MenuItem>
                    <MenuItem value="C">{ props.useGregorianChords ? "Do" : "C" }</MenuItem>
                    <MenuItem value="Db">{ props.useGregorianChords ? "Do#/Reb" : "C#/Db" }</MenuItem>
                    <MenuItem value="D">{ props.useGregorianChords ? "Re" : "D" }</MenuItem>
                    <MenuItem value="Eb">{ props.useGregorianChords ? "Re#/Mib" : "D#/Eb" }</MenuItem>
                    <MenuItem value="E">{ props.useGregorianChords ? "Mi" : "E" }</MenuItem>
                    <MenuItem value="F">{ props.useGregorianChords ? "Fa" : "F" }</MenuItem>
                    <MenuItem value="Gb">{ props.useGregorianChords ? "Fa#/SolB" : "F#/Gb" }</MenuItem>
                    <MenuItem value="G">{ props.useGregorianChords ? "Sol" : "G" }</MenuItem>
                    <MenuItem value="Ab">{ props.useGregorianChords ? "Sol#/Lab" : "G#/Ab" }</MenuItem>
                </Select>
            </StyledControl>);
}
