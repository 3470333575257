import React, { useContext, useEffect, useRef, useState } from "react";
import './ViewBucket.css';
import { useParams } from "react-router-dom";
import { SongHead } from "../../model/SongHead";
import { SongList } from "./SongList";
import LocalDatabase from "../../repository/LocalDatabase";
import IconMenu from "../Menu/IconMenu";
import { CircularProgress, Modal, Stack, styled } from "@mui/material";
import { SearchContext } from "../atoms/SearchContext";
import { getSongList, getSongsByArtist, getSongsByTag } from "../../services/SongServiceBis";
import { SongContext } from "../atoms/SongContext";
import { SearchButton } from "../atoms/Buttons/Search/SearchButton";
import { RandomSearchButton } from "../atoms/Buttons/RandomSearchButton";
import { ExportBucketButton } from "../atoms/Buttons/ExportBucket/ExportBucketButton";
import { DownloadBucketButton } from "../atoms/Buttons/DownloadBucket/DownloadButton";
import { AddSongButton } from "../atoms/Buttons/AddSongButton";
import { LoginButton } from "../atoms/Buttons/LoginButton";
import { LogoutButton } from "../atoms/Buttons/LogoutButton";

type Props = {
    bucket?: string,
    username?: string,
    abc?: boolean,
    tag?: string,
    artist?: string,
    useGregorianChords?: boolean
    backgroundColor?: string;
    foregroundColor?: string;
}

export default function ViewBucket(props: Props) {

    const StyledContainer = styled("div")`
        --color-background: ${props.backgroundColor || '#1976d2'};
        --color-foreground: ${props.foregroundColor || '#f1f1f1'};

        @media (min-width: 1200px) {
            max-width: 82%;
            margin: auto;
        }
    `;

    const StyledTitle = styled("div")`
        display: inline-flex;
        gap: 1rem;
    
        justify-content: center;
        align-items: center;

        width: 100%;
    `

    const params = useParams();

    const abc = props.abc ? props.abc : params.abc ? true : false;
    const bucket = props.bucket ? props.bucket : params.bucket;
    // const username = props.username ? props.username : params.username;
    const tag = props.tag !== undefined ? props.tag : params.tag;
    const artist = props.artist !== undefined ? props.artist : params.artist;
    const useGregorianChords = props.useGregorianChords ?? false;
    const localDatabase = LocalDatabase();
    const [ wait, setWait ] = useState<boolean>(false);

    const allSongs = useRef<SongHead[]>([]);
    let [songsToDisplay, setSongsToDisplay] = useState<(SongHead)[]>([])

    const getSongsFromLocaleOrRemote = async () => {
        setWait(true);
        await localDatabase
            .getSongsFromLocalStorage()
            .then((songs: SongHead[])=>{
                if (null !== songs && songs.length > 0) {
                    mergeIntoAllSongs(songs);
                }
                setWait(false);
            }).catch(() => setWait(false));
        if (undefined !== bucket) {
            if (undefined !== tag) {
                retriveSongsByTag("B-" + bucket + "-T-" + tag, 0);
            } else if (undefined !== artist) {
                retriveSongsByArtist("B-" + bucket + "-A-" + artist, 0);
            } else {
                retriveSongList("B-" + bucket, 0);
            }
        }
    }

    useEffect(()=>{
        getSongsFromLocaleOrRemote();
    // eslint-disable-next-line
    }, [ bucket, tag, artist ]);

    const songContext = useContext(SongContext);
    const PAGE_SIZE = 100;

    const retriveSongList = (key: string, page: number) => {
        if (!songContext.songListMap.has(key)) {
            setWait(true);
            getSongList(bucket, { page: page, size: PAGE_SIZE })
                .then(songList => {
                    handleSongListFromRemote(key, songList, retriveSongList, page);
                })
        } else {
            handleSongListFromRemote(key);
        }
    }

    const retriveSongsByTag = (key: string, page: number) => {
        if (!songContext.songListMap.has(key)) {
            setWait(true);
            getSongsByTag(bucket, tag, { page: page, size: PAGE_SIZE })
                .then(headerSongList => {
                    handleSongListFromRemote(key, headerSongList, retriveSongsByTag, page)
                })
        } else {
            handleSongListFromRemote(key);
        }
    }

    const retriveSongsByArtist = (key: string, page: number) => {
        if (!songContext.songListMap.has(key)) {
            setWait(true);
            getSongsByArtist(bucket, artist, { page: page, size: PAGE_SIZE })
                .then(headerSongList => {
                    handleSongListFromRemote(key, headerSongList, retriveSongsByArtist, page)
                })
        } else {
            handleSongListFromRemote(key);
        }
    }

    const mergeIntoAllSongs = (songs: SongHead[]) => {
        songs.forEach(s => { 
            const conflictSong: SongHead[] = allSongs.current.filter((otherSong:SongHead) => otherSong.slug === s.slug);
            if (conflictSong.length > 0) {
                if (conflictSong[0].lastUpdate < s.lastUpdate) {
                    const idx = allSongs.current.indexOf(conflictSong[0]);
                    allSongs.current[idx] = s;
                }
            } else {
                allSongs.current.push(s); 
            }
        })
    }

    const handleSongListFromRemote = (key: string, songHeadList?: SongHead[], caller?: any, page?: number) => {
        if (!songContext.songListMap.has(key)) {
            mergeIntoAllSongs(songHeadList);
            if (caller !== undefined && page !== undefined && songHeadList.length > 0) {
                page += 1;
                caller(key, page);
            } else {
                songContext.updateSongListMap(key, allSongs.current);
                songContext.updateSongListMap('current', allSongs.current);
                setSongsToDisplay(allSongs.current);
                setWait(false);
            }
        } else {
            const songList = songContext.songListMap.get(key);
            allSongs.current = songList;
            setSongsToDisplay(songList);
            setWait(false);
        }
    }

    const [search, setSearch] = useState<string>('');
    const handleSearch = (value:string) => {
        const filter:string = value.toLowerCase();

        setSearch(filter);
        if (filter.length > 0) {
            let filteredSongs = allSongs.current.filter(function(s) {
                return s.artist.toLowerCase().includes(filter) || 
                        s.title.toLowerCase().includes(filter) ||
                        s.tags?.some(t=>t.toLowerCase().includes(filter));
            })
            setSongsToDisplay(filteredSongs);
        } else {
            setSongsToDisplay(allSongs.current);
        }
    }

    const addSongUrl = abc ? '/' + bucket + '/abc/new' : "/" + bucket + '/new'
    return (
        <StyledContainer>
            <SearchContext.Provider value={{
                text: search,
                updateSearch: (text: string) => { 
                    setSearch(text); 
                }
            }}>
            <IconMenu>
                    <SearchButton handleSearch={ handleSearch } />
                    <RandomSearchButton />
                    <ExportBucketButton bucket={bucket} />
                    <DownloadBucketButton bucket={bucket} />
                    <AddSongButton addSongUrl={ addSongUrl } />
                    <LoginButton />
                    <LogoutButton />
            </IconMenu>
            <StyledTitle>
                <h3>Canzoniere: <a href={'/' + bucket}>{ bucket }</a></h3>
                <h3>{ tag && "tag: " + tag }</h3>
                <h3>{ artist && "artista: " + artist }</h3>
                <h3>{ allSongs.current.length } Canzoni</h3>
            </StyledTitle>
            <SongList bucket={ bucket } 
                abc={ abc } 
                songs={ songsToDisplay } 
                useGregorianChords={ useGregorianChords }/>
            <Modal open={wait}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: 1, height: "100vh" }}
                    >
                    <CircularProgress thickness={5} color="secondary" />
                </Stack>
            </Modal>
            </SearchContext.Provider>
        </StyledContainer>
    );
}
