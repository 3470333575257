import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { logout } from '../../services/AuthServiceBis';

type Props = {
    redirect?: string
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export default function Logout(props: Props) {

    const navigate = useNavigate();
    const params = useParams();
    logout();

    const redirect = props.redirect ?? params.redirect;
    const performLogout = ()  => {
        navigate(redirect);
    }

    return <Container>
        <Button onClick={performLogout} 
            color="primary" 
            variant="contained" 
            size="large">Confirm Logout</Button>
        </Container>
}