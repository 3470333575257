import { ListItem, Fab, Tooltip } from "@mui/material";
import CasinoIcon from '@mui/icons-material/Casino';
import React, { useContext } from "react";
import { SongContext } from "../SongContext";
import { useNavigate } from "react-router-dom";

export const RandomSearchButton = () => {

    const songContext = useContext(SongContext);
    const navigate = useNavigate();
    const currentSongs = songContext.songListMap.get('current');

    const randomChoice = () => {
        const total = currentSongs.length;
        const index = Math.floor(Math.random() * total);
        if (currentSongs.length > 0) {
            const slug = currentSongs[index].slug;
            navigate('/' + slug + '/view')
        }
    }

    return <ListItem>
            <Fab onClick={ randomChoice }>
                <Tooltip title={"search"}>
                    <CasinoIcon />
                </Tooltip>
            </Fab>
        </ListItem>
}

