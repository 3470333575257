// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --color-background: #1976d2;
    --color-foreground: #f1f1f1;
    --color-accent: #F7B32B;
    --color-divergent: #F06449;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2BAA2B;IAC3B,uBAAuB;IACvB,0BAA0B;AAC9B","sourcesContent":[":root {\n    --color-background: #1976d2;\n    --color-foreground: #f1f1f1;\n    --color-accent: #F7B32B;\n    --color-divergent: #F06449;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
