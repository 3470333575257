import { createContext } from 'react';
import { SongHead } from '../../model/SongHead';

type SongContextStateType = {
    songListMap: Map<string, SongHead[]>;
    updateSongListMap: (key: string, value: SongHead[]) => void;
  };
  
export const SongContext = createContext<SongContextStateType>({
  songListMap: new Map<string, SongHead[]>(), updateSongListMap: (key: string, value: SongHead[]) => {}
});  