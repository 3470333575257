import { ListItem, Fab, Tooltip } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import React from "react";
import { isUserAuthenticated } from "../../../services/AuthServiceBis";
import { useNavigate } from "react-router-dom";

export const LoginButton = () => {
    const navigate = useNavigate();

    return !isUserAuthenticated() &&
        <ListItem>
            <Fab onClick={()=>navigate('/login')}>
                <Tooltip title="Login">
                    <LoginIcon />
                </Tooltip>
            </Fab>
        </ListItem>
}

