import { ListItem, Fab, Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import { isUserAuthenticated } from "../../../services/AuthServiceBis";

export const AddSongButton = (props: { addSongUrl: string }) => {
    return isUserAuthenticated() &&
    <ListItem>
        <Fab onClick={()=>window.location.href=props.addSongUrl}>
            <Tooltip title={"add song"}>
                <AddIcon />
            </Tooltip>
        </Fab>
    </ListItem> 
}

