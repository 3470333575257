import { ResponseMessage } from "../model/ResponseMessage";
import { Song } from "../model/Song";
import { SongHead } from "../model/SongHead";
import { SongResponseMessage } from "../model/SongResponseMessage";
import { Page, Slug } from "./ISongService"
import { getBaseUrl } from "../getBaseUrl"
import { isUserAuthenticated } from "./AuthServiceBis";

export const viewSong = async (bucket: string, artist: string, title: string): Promise<Song> => {
        let url = getUrl(bucket, artist, title) + '/get';
        return getSong(url);
    }

export const getSongBySlug = async(bucket: string, slug: string): Promise<Song> => {
    let url = getUrlBySlug(bucket, slug) + '/get';
    return getSong(url);
}

export const getSongsBySearch = async(bucket: string, search: string, page: Page) : Promise<Array<SongHead>> => {
    const url = addPageParams(getBaseUrl() + "/song/" + bucket +"/search/" + search, page);
    let options = getOptions();
    let list = await fetch(url, options)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
    return list;
}

export const getSongList = async (bucket: string, page: Page) : Promise<Array<SongHead>> => {
    if (page === undefined) return;
    const url = addPageParams(getBaseUrl() + "/song/" + bucket +"/list", page);
    let options = getOptions();
    let list = await fetch(url, options)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
    return list;
}

const addPageParams = (url: string, page: Page): string  => {
    return url + '?page=' + page.page + '&size=' + page.size;
}

export const getSongsByTag = async(bucket: string, tag: string, page: Page) : Promise<Array<SongHead>> => {
    const url = addPageParams(getBaseUrl() + "/song/" + bucket +"/tag/" + tag, page);
    let options = getOptions();
    let list = await fetch(url, options)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
    return list;
}

export const getSongsByArtist = async(bucket: string, artist: string, page: Page) : Promise<Array<SongHead>> => {
    const url = addPageParams(getBaseUrl() + "/song/" + bucket +"/artist/" + artist, page);
    let options = getOptions();
    let list = await fetch(url, options)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
    return list;
}

export const deleteSong = async (bucket: string, artist: string, title: string): Promise<ResponseMessage> => {
    let url = getBaseUrl() + '/song/' + bucket + '/' + artist + '/' + title + '/delete';
    const options = getOptions('DELETE');
    let message : ResponseMessage = {
        outcome: false,
        message: 'Something went wrong'
    }
    await fetch(url, options)
            .then(res=>res.json())
            .then(res=>{
                if (res.result) {
                    let redirectUrl = '/' + bucket + '/list'
                    message.outcome = true
                    message.message = redirectUrl
                }
            })
            .catch(err => {
                // console.log(err)
                message.outcome = false
                message.message = err
            })
    return Promise.resolve(message)
}

export const saveSong = async(bucket: string, rawText: string): Promise<SongResponseMessage> => {
    let url = getBaseUrl() + '/song/' + bucket + '/save';
    let content = { rawText: rawText, force: false }
    const options = getOptions('POST', content);
    return fetch(url, options)            
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json());
}

export const magicWand = async(bucket: string, rawText: string): Promise<Song> => {
    let url = getBaseUrl() + '/song/' + bucket + '/magicwand';
    let content = rawText
    const options = getOptions('POST', content);
    return fetch(url, options)            
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json());
}

export const songify = async(bucket: string, text: string): Promise<Song> => {
    let url = getBaseUrl() + '/song/' + bucket + '/songify';
    let options = getOptions('POST', text)

    return await fetch(url, options)            
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json());
}

export const exportSong = async(bucket: string): Promise<Response> => {
    const options = getOptions('POST');
    const url = getBaseUrl() + '/bucket/' + bucket + '/export'
    return fetch(url, options);
}

    
export const duplicateChords = async (textWithChords: string, textWithoutChords: string): Promise<ResponseMessage> => {
    const content = {
        textWithChords: textWithChords,
        textWithoutChords: textWithoutChords
    }
    const options = getOptions('POST', content);
    const url = getBaseUrl() + '/text/chords/duplicate';
    let message : ResponseMessage = {
        outcome: false,
        message: 'Something went wrong'
    }
    await fetch(url, options)
            .then(response=>{
                return response.text()
            })
            .then(data => {
                message.outcome = true
                message.message = data
            })
            .catch(err => {
                // console.log(err)
                message.outcome = false
                message.message = err
            })
    return Promise.resolve(message)
}

export const deleteChords = async (textWithChords: string): Promise<ResponseMessage> => {
    const options = getOptions('POST', textWithChords);
    const url = getBaseUrl() + '/text/chords/delete';
    let message : ResponseMessage = {
        outcome: false,
        message: 'Something went wrong'
    }
    await fetch(url, options)
            .then(response=>{
                return response.text()
            })
            .then(data => {
                message.outcome = true
                message.message = data
            })
            .catch(err => {
                // console.log(err)
                message.outcome = false
                message.message = err
            })
    return Promise.resolve(message)
}

export const countSong = async (bucket?: string, artist?: string, tag?: string): Promise<number> => {
    const options = getOptions('GET');
    let url = getBaseUrl();
    if (bucket && artist) url += '/controller/song/' + bucket + '/artist/' + artist + '/count';
    else if (bucket && tag) url += '/controller/song/' + bucket + '/tag/' + tag + '/count';
    else if (bucket) url += '/controller/song/' + bucket + '/count';
    else url += '/controller/song/count';
    return fetch(url, options)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
}

export const getSong = async (url: string) : Promise<Song>  => {
    let options = getOptions();
    let song = await fetch(url, options)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
    return song;
}

export const splitSlug = (slug: string): Slug => {
    const tmp = slug.split("/");
    return {
        bucket: tmp[0],
        artist: tmp[1],
        title: tmp[2]
    }
}

const getOptions = (method?: string, content?: string | object) => {
    let token = localStorage.getItem('authorization-token');
    let headers: Record<string, any> = method !== 'DELETE' ? { 
        'Accept': 'application/json', 
        'Content-Type': 'application/json',
    } : {};
    if (isUserAuthenticated()) {
        headers.Authorization = "Bearer " + token;
    }
    let options: Record<string, any> = { 
        //credentials: 'include',
        method: undefined === method ? 'GET' : method,
        headers: headers,
        //withCredentials: true,
    };
    if (content !== undefined) {
        options.body = (content instanceof Object) ? JSON.stringify(content) : content;
    }
    return options;
}

const getUrl = (bucket: string, artist: string, title: string): string => {
    return getBaseUrl() + '/song/' + 
                bucket + '/' + 
                artist + '/' + 
                title; 
}

const getUrlBySlug = (bucket: string, slug: string): string => {
    return getBaseUrl() + '/song/' + slug; 
}