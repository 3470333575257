import React, { useEffect, useRef, useState } from "react";
import { Song, Chord as ChordProChord } from '../../model/Song';
import './ChordProViewer.css';
import GuitarChord from 'react-guitar-chords';
import { Grid } from "@mui/material";
import styled from "styled-components";
import ChordSheetJS, { Song as SongChordsheetJS, Chord } from "chordsheetjs"
import { guitarChords } from "../utils/guitar-chords";
import DivFormatter from "./DivFormatter";
import { toGregorianChord } from "../utils/chords";

const Container = styled.section``;

const Title = styled.h2`
    font-size: 38pt;
    width: 100%;
`;

const Artist = styled.h4`
    font-size: 24pt;
    width: 100%;

    & a {
        color: black;
        text-decoration: none;
    }
`;

function parseSong(song: Song): SongChordsheetJS {
    if (undefined === song) return null;
    const parser = new ChordSheetJS.ChordProParser();
    return parser.parse(song.raw);
}

function formatSong(song: SongChordsheetJS, useGregorianChords: boolean): JSX.Element {
    if (undefined === song) return null;
    // const formatter = new ChordSheetJS.HtmlDivFormatter({
    //     evaluate: true
    // });
    const formatter = DivFormatter();
    return formatter.format(song, useGregorianChords);
}

function getTransposeDistance(song: Song, toKey: string) : number {
    const tones = [ 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab' ]
    const alternativeTones = [ 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#' ]
    let distance = 0;
    if (undefined !== toKey && null !== song.chords && song.chords.length > 0) {
        const firstSongChord = song.chords.length > 0 ? Chord.parse(song.chords[0].name) : "C";
        if (null == firstSongChord) return 0;
        let normalizedChord = firstSongChord.normalize().toString();
        
        let indexOfFirstChord: number = tones.indexOf(normalizedChord);
        if (indexOfFirstChord === -1) {
            normalizedChord = normalizedChord.substring(0, 2).normalize().toString();
            indexOfFirstChord = tones.indexOf(normalizedChord);
            if (indexOfFirstChord === -1) {
                indexOfFirstChord = alternativeTones.indexOf(normalizedChord);
            }
            if (indexOfFirstChord === -1) {
                normalizedChord = normalizedChord.substring(0, 1).normalize().toString();
                indexOfFirstChord = tones.indexOf(normalizedChord)
            }
        }

        toKey = toKey.length === 0 ? normalizedChord : toKey;
        let indexOfDesiredKey: number = tones.indexOf(toKey);
        distance = indexOfDesiredKey - indexOfFirstChord;
    }
    return distance;
}

type Props = {
    song: Song,
    showChords: boolean,
    useGregorianChords: boolean,
    toKey?: string
}

export default function ChordProViewer(props: Props) {

        let [html, setHtml] = useState(<></>);
        const chords = useRef([]);
        const chordNames = useRef([]);

        const chordAlreadyAdded = (chordName: string): boolean => {
            return chordNames.current.indexOf(chordName) > 0;
        }

        const mapChords = (distance: number) => {
            chords.current = [];
            chordNames.current = [];
            props.song.chords.forEach((c: ChordProChord) => {
                const chord = Chord.parse(c.name);
                if (null == chord) return;
                const transposedChord = chord.transpose(distance);

                if (c.frets || undefined !== guitarChords[ transposedChord.toString() as keyof typeof guitarChords ]) {                    
                    let newChord = {
                        name: props.useGregorianChords ? toGregorianChord(transposedChord.toString()) : transposedChord.toString(),
                        frets: c.frets || guitarChords[ transposedChord.toString() as keyof typeof guitarChords ].frets
                    };
                    if (!chordAlreadyAdded(newChord.name)) {
                        chords.current.push(newChord)
                        chordNames.current.push(newChord.name);
                    }
                }
            })
        }

        useEffect(() => {
            try {
                let distance = getTransposeDistance(props.song, props.toKey);
                let parsed = parseSong(props.song);
                
                parsed = parsed.transpose(distance, { normalizeChordSuffix: true })
                if (props.showChords) {
                    mapChords( distance );
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
                html = formatSong(parsed, props.useGregorianChords);
                setHtml(html)
            } catch (e) { console.error(e); }
        }, [props.song, props.toKey])

        return (
            <Container className="font-loader">
                <Grid container className="chords" sx={{ mt: 3 }} style={{ width: "100%", alignItems: "center" }}>
                {props.showChords && chords.current.map((c:ChordProChord, i) => {
                    if (null != c) {
                        return <GuitarChord 
                                    className="float-start" 
                                    key={i} 
                                    chordName={c.name} 
                                    style={{ width: '60%' }}
                                    frets={c.frets.map(f=>parseInt(f) < 0? 'x': f)} />
                    }
                    return null;
                })}
                </Grid>
                <Title>{props.song.title}</Title>
                <Artist><a href={'/'+ props.song.bucket + '/artist/' + props.song.artist }>{props.song.artist}</a></Artist>
                {html}
            </Container>
        );
}
