/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import './ViewSong.css';
import { AbcViewer } from '../atoms/Abcjs'
import { Song } from '../../model/Song';
import { Chip, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ChordProViewer from "../atoms/ChordProViewer";
import LocalDatabase from "../../repository/LocalDatabase";
import IconMenu from "../Menu/IconMenu";
import { BackButton } from "../atoms/Buttons/BackButton";
import { EditSongButton } from "../atoms/Buttons/EditSongButton";
import { LoginButton } from "../atoms/Buttons/LoginButton";
import { LogoutButton } from "../atoms/Buttons/LogoutButton";
import { TransposeButton } from "../atoms/Buttons/Transpose/TransposeButton";
import { viewSong } from "../../services/SongServiceBis";

type Props = {
    abc?: boolean,
    bucket?: string,
    artist?: string,
    title?: string
    useGregorianChords?: boolean
};

function stopScroll(scrollInterval: NodeJS.Timer) {
    clearInterval(scrollInterval);        
}

function startScroll(scrollerSpeed: number): NodeJS.Timer {
    let height = window.scrollY;
    const scrollInterval = setInterval(()=>{ 
        height += scrollerSpeed;
            window.scroll({
                top: height,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
    return scrollInterval;
}

const Container = styled('div')`
    padding-top: 1rem;
`

const ChipContainer = styled('div')`
    margin-left: 1rem;
    gap: .5rem;
    display: inline-flex;
`

export default function SongViewer(props: Props) {
    const params = useParams();

    const bucket = props.bucket? props.bucket : params.bucket; 
    const title = props.title? props.title : params.title; 
    const artist = props.artist? props.artist : params.artist; 
    const abc = props.abc? props.abc : params.abc ? true : false; 
    const useGregorianChords = props.useGregorianChords ?? false;

    const localDatabase = LocalDatabase();

    let [scrolling, setScrolling] = useState<Boolean>(false);
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timer>(undefined);
    const [key, setKey] = useState('');
    const [song, setSong] = useState<Song>({
        title: "", 
        artist: "", 
        bucket: "",
        slug: "",
        raw: "",
        abc: false,
        lastUpdate: new Date().getTime(),
        tags: [],
        chords: []
    });
    const navigate = useNavigate();

    let scrollerSpeed: number = 2;

    useEffect(() => {
        localDatabase.getSong(bucket, title, artist)
            .then((song:Song)=>{
                if (song === undefined) {
                    viewSong(bucket, artist, title)
                        .then((song: Song) => {
                            setSong(song)
                        })
                        .catch(err => console.log('Request Failed', err)); 
                } else {
                    setSong(song);
                }
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function onTranspose(e: React.ChangeEvent<HTMLSelectElement>) {
        let toKey = e.target.value;
        setKey(toKey);
    }

    useEffect(()=>{
        if (scrolling) {
            const ret = startScroll(scrollerSpeed);
            setScrollInterval(ret);
        } else {
            stopScroll(scrollInterval)
        }
        setScrolling(scrolling);
    }, [scrolling]);

    const onScroll = (e: React.MouseEvent<HTMLElement>) => {
        scrolling = scrolling ? false : true
        setScrolling(scrolling)
    }

    const isAbc = () => { return abc || song?.abc === true; }
    return (
        <>
            { isAbc() ? 
                <IconMenu>
                    <EditSongButton editSongUrl={ '/' + song?.slug + '/abc/edit' } />
                    <BackButton />
                    <LoginButton />
                    <LogoutButton />
                </IconMenu>  
                :
                <IconMenu>
                    <TransposeButton transpose={ onTranspose } useGregorianChords={useGregorianChords} />
                    <EditSongButton editSongUrl={ '/' + song?.slug + '/edit' } />
                    <BackButton />
                    <LoginButton />
                    <LogoutButton />
                </IconMenu> }
            <Container className="container mt-3 sheet" onClick={ onScroll }>
                <ChipContainer>
                {song?.tags?.map((e, i)=><Chip key={i} className="me-1" label={e} onClick={()=>navigate('/' + bucket + '/tag/' + e)} />)}
                </ChipContainer>
                { isAbc() ? 
                    <AbcViewer abcNotation={song?.raw}
                        parserParams={{}}
                        engraverParams={{ responsive: 'resize' }}
                        renderParams={{ viewportHorizontal: true }}
                    />
                    :
                    <ChordProViewer song={song} 
                        toKey={key} 
                        showChords={true} 
                        useGregorianChords={useGregorianChords} />}
            </Container>
        </>
    );
}
