import { ListItem, Fab, Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from "react";

export const BackButton = () => {
    return <ListItem>
            <Fab onClick={()=>window.history.back()}>
                <Tooltip title={"back"}>
                    <ArrowBackIcon />
                </Tooltip>
            </Fab>
        </ListItem>
}

