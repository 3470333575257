export const TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 1024;
export const BIG_DESKTOP_BREAKPOINT = 1366;

export const mediaQueries: {
    mobileOnly: string;
    tabletOnly: string;
    tablet: string;
    desktop: string;
    bigDesktop: string;
    noDesktop: string;
  } = {
    mobileOnly: `(max-width: ${TABLET_BREAKPOINT - 1}px)`,
    tablet: `(min-width: ${TABLET_BREAKPOINT}px)`,
    tabletOnly: `(min-width: ${TABLET_BREAKPOINT}px) and (max-width: ${
      DESKTOP_BREAKPOINT - 1
    }px)`,
    desktop: `(min-width: ${DESKTOP_BREAKPOINT}px)`,
    bigDesktop: `(min-width: ${BIG_DESKTOP_BREAKPOINT}px)`,
    noDesktop: `(max-width: ${DESKTOP_BREAKPOINT - 1}px)`,
};