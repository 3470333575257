import React, { useRef } from "react";
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Fab, Tooltip, Typography } from "@mui/material";
import LocalDatabase from "../../../../repository/LocalDatabase";
import { SongHead } from "../../../../model/SongHead";
import { getSongBySlug, getSongList } from "../../../../services/SongServiceBis";

type Props = {
    bucket: string
    fab?: boolean
}

export default function DownloadBucketIcon(props: Props) {

    const localDatabase = LocalDatabase();
    const cloudOffline = useRef<HTMLDivElement>(null);
    const loading = useRef<HTMLDivElement>(null);

    const onDownloadBucket = (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        loading.current.style.display = "block"
        cloudOffline.current.style.display = "none"
        getSongs(0);
    }

    const getSongs = (page: number) => {
        const size = 50;
        getSongList(props.bucket, { page: page, size: size })
            .then((songHead: SongHead[]) => {
                if (songHead.length > 0) {
                    songHead.forEach(sh=> {
                        getSongBySlug(props.bucket, sh.slug)
                            .then(s=>{
                                localDatabase.addSongToLocalStorage(s);
                            });
                    })
                    page += 1;
                    getSongs(page);
                } else {
                    loading.current.style.display = "none"
                    cloudOffline.current.style.display = "initial"
                }
            })
    }

    return props.fab? 
            <Fab onClick = { onDownloadBucket } >
                <Tooltip title={"songbook available offline"} ref={ cloudOffline }>
                    <CloudOffIcon />
                </Tooltip>
                <span ref={loading} style={{ display: "none" }}>
                    <CircularProgress style={{ color: "white" }} />
                </span>
            </Fab>
            :
            <Box sx={{ p: 2 }} style={{ textAlign: 'center', cursor:"pointer" }}>
                <Tooltip title={"songbook available offline"}
                            ref={ cloudOffline }>
                    <CloudOffIcon 
                        style={{ cursor: "pointer"}} 
                        onClick = { onDownloadBucket } 
                        />
                </Tooltip>
                <span ref={loading} style={{ display: "none" }}>
                <CircularProgress style={{ color: "white" }} />
                </span>
                <Typography sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>Download</Typography>
            </Box>
    
}
