// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-box {
    flex-direction: column;
    margin-bottom: auto;
    align-content: stretch; 
}

.box > .row {
    flex-wrap: wrap;
    /* display: flex; */
}

.row>* {
    width: fit-content;
}

.chord {
    color: #0C0C0C;
    font-weight: bold;
    font-family: 'Inter';
}

div.chorus {
    border-left: 2pt solid #A0A0A0;
    padding-left: 5pt;
}

div.italic {
    font-style: italic;
}

div.chords {
    flex-direction: row;
}
div.chords button {
    display: none;
}

div.chords > div {
    flex-direction: row;
}

div.chords svg > text:first-child {
    font-weight: bold;
}

.font-loader {
    font-family: 'Raleway';
}`, "",{"version":3,"sources":["webpack://./src/component/atoms/ChordProViewer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,8BAA8B;IAC9B,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".mini-box {\n    flex-direction: column;\n    margin-bottom: auto;\n    align-content: stretch; \n}\n\n.box > .row {\n    flex-wrap: wrap;\n    /* display: flex; */\n}\n\n.row>* {\n    width: fit-content;\n}\n\n.chord {\n    color: #0C0C0C;\n    font-weight: bold;\n    font-family: 'Inter';\n}\n\ndiv.chorus {\n    border-left: 2pt solid #A0A0A0;\n    padding-left: 5pt;\n}\n\ndiv.italic {\n    font-style: italic;\n}\n\ndiv.chords {\n    flex-direction: row;\n}\ndiv.chords button {\n    display: none;\n}\n\ndiv.chords > div {\n    flex-direction: row;\n}\n\ndiv.chords svg > text:first-child {\n    font-weight: bold;\n}\n\n.font-loader {\n    font-family: 'Raleway';\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
